import React, { useState, useEffect } from "react";
import { AppShell, Header, Footer as FooterContainer } from "@mantine/core";
import { Footer } from "../Footer";
import { Headerv2 } from "../Header";
import { PropertyFormv2, PropertiesContainer, PropertyDetail } from "..";
import { lang_amh, lang_en } from "../../utils/translations";
import { Route, Routes, useLocation } from "react-router-dom";
import { LandingPage } from "../LandingPage";

const Home = () => {
  const [lang, setLang] = React.useState<string>("en");
  const [langMessages, setlangMessages] = useState(lang_en);
  const { pathname } = useLocation();
  const routelabel = pathname.includes("add-property")
    ? langMessages?.homePageLinkLabel
    : langMessages?.routeLabel;

  const routeLink = pathname.includes("add-property") ? "/" : "/add-property";

  useEffect(() => {
    const langSelected = localStorage.getItem("language_selected") ?? "en";
    setlangMessages(langSelected && langSelected === "en" ? lang_en : lang_amh);
    setLang(langSelected);
  }, []);

  useEffect(() => {
    const selectedLangMessages = lang === "en" ? lang_en : lang_amh;
    localStorage.setItem("language_selected", lang);
    setlangMessages(selectedLangMessages);
  }, [lang]);

  return (
    <>
      <AppShell
        sx={{
          minWidth: "380px",
        }}
        styles={{
          main: {
            padding: 0,
            overflow: "hidden",
          },
        }}
        footer={<Footer />}
        header={
          <Headerv2
            lang={lang}
            setLanguage={setLang}
            routeLabel={routelabel}
            comingSoon={langMessages.comingSoon}
            viewAllLabel={langMessages.viewAllLabel}
            homePageLinkLabel={langMessages.homePageLinkLabel}
            routeLink={routeLink}
          />
        }
      >
        <Routes>
          <Route path="/" element={<LandingPage messages={langMessages} />} />
          <Route
            path="add-property"
            element={<PropertyFormv2 messages={langMessages} />}
          />
          <Route
            path="search"
            element={<PropertiesContainer messages={langMessages} />}
            // element={<ComingSoon {...langMessages} />}
          />
          <Route
            path="property/:propertyId"
            element={<PropertyDetail messages={langMessages} />}
          />
        </Routes>
      </AppShell>
    </>
  );
};

export { Home };
