import { Group, Text, useMantineTheme, rem } from "@mantine/core";
import { IconUpload, IconPhoto, IconX } from "@tabler/icons-react";
import { Dropzone, DropzoneProps } from "@mantine/dropzone";
import { UseFormReturnType } from "@mantine/form";
import { FormFields } from "../../Types/FormTypes";
import { useState } from "react";
import { ImagePreviewThumbs } from "./ImagePreviewThumbs";
import { CSSProperties } from "react";

const thumbsContainer: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

export const ImageDropV2 = (
  props: Partial<DropzoneProps> & {
    form: UseFormReturnType<FormFields, (values: FormFields) => FormFields>;
    tooManyErrorMsg?: string;
    drag_drop?: string;
    image_upload_desc?: string;
  }
) => {
  const { form, tooManyErrorMsg, drag_drop, image_upload_desc } = props;
  const theme = useMantineTheme();
  const [showMaxFilesError, setshowMaxFilesError] = useState<boolean>(false);
  const [showError, setshowError] = useState<boolean>(false);

  const handleThumbDelete = (file: any) => {
    setshowMaxFilesError(false);
    form.values.imageFiles.filter((curr) => curr !== file);
    form.setValues({
      ...form.values,
      imageFiles: form.values.imageFiles.filter((curr) => curr !== file),
    });
  };
  return (
    <>
      <Dropzone
        onDrop={async (files) => {
          if (form.values.imageFiles.length + files.length <= 20) {
            form.setValues({
              ...form.values,
              imageFiles: [...form.values.imageFiles, ...files],
            });
          } else {
            setshowMaxFilesError(true);
          }
        }}
        onReject={(files) => setshowError(true)}
        maxSize={8410109}
        maxFiles={20}
        accept={["image/png", "image/jpeg", "image/jpg", "image/avif"]}
        {...props}
      >
        <Group
          position="center"
          spacing="xl"
          style={{ minHeight: rem(220), pointerEvents: "none" }}
        >
          <Dropzone.Accept>
            <IconUpload
              size="3.2rem"
              stroke={1.5}
              color={
                theme.colors[theme.primaryColor][
                  theme.colorScheme === "dark" ? 4 : 6
                ]
              }
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              size="3.2rem"
              stroke={1.5}
              color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto size="3.2rem" stroke={1.5} />
          </Dropzone.Idle>

          <div>
            {showMaxFilesError ? (
              <Text size="xl" color="red" weight={800} inline>
                {tooManyErrorMsg}
              </Text>
            ) : showError ? (
              <Text size="xl" color="red" weight={800} inline>
                {tooManyErrorMsg}
              </Text>
            ) : (
              <>
                <Text size="xl" inline>
                  {drag_drop}
                </Text>
                <Text mt="sm" size="sm" color="dimmed" inline>
                  {image_upload_desc}
                </Text>
              </>
            )}
          </div>
        </Group>
      </Dropzone>
      <aside style={thumbsContainer}>
        {form.values.imageFiles.map((currentThumb) => {
          return (
            <ImagePreviewThumbs
              image={currentThumb}
              handleThumbDelete={handleThumbDelete}
            />
          );
        })}
      </aside>
    </>
  );
};
