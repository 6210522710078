import { Box } from "@mantine/core";
import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

interface CarouselProps {
  images: {
    original: string;
    thumbnail: string;
  }[];
}

const nonFullscreenStyle = {
  ".image-gallery-slide .image-gallery-image": {
    height: "280px",
    width: "100%",
    backgroundColor: "black",
  },
  ".image-gallery-thumbnail .image-gallery-thumbnail-image": {
    height: "80px",
    objectFit: "contain" as any,
  },
  ".image-gallery-thumbnail .image-gallery-thumbnail-inner": {
    backgroundColor: "black",
  },
};
const t = {
  ".image-gallery-thumbnail .image-gallery-thumbnail-inner": {
    height: "55px",
    backgroundColor: "black",
  },
};
const Carousel = (props: CarouselProps) => {
  const { images } = props;
  const [fullScreen, setfullScreen] = useState(false);
  const style = fullScreen ? t : nonFullscreenStyle;
  return (
    <Box sx={style}>
      <ImageGallery
        onScreenChange={(val) => setfullScreen(val)}
        useBrowserFullscreen={false}
        items={images}
      />
    </Box>
  );
};

export { Carousel };
