import {
  createStyles,
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
} from "@mantine/core";
import { IconGauge, IconShieldLock, IconCookie } from "@tabler/icons-react";
import { Translations } from "../../utils/translations";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(34),
    fontWeight: 900,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(24),
    },
  },

  description: {
    maxWidth: 600,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
    },
  },
}));

export function FeaturesCards(props: {
  messages: Translations;
  learnMoreRef: React.MutableRefObject<HTMLDivElement>;
}) {
  const { messages, learnMoreRef } = props;
  const { classes, theme } = useStyles();
  const cardData = [
    {
      title: messages.features_body_perf_title,
      description: messages.features_body_perf_label,
      icon: IconGauge,
    },
    {
      title: messages.features_body_privacy_title,
      description: messages.features_body_privacy_label,
      icon: IconShieldLock,
    },
    {
      title: messages.features_body_integrated_title,
      description: messages.features_body_integrated_label,
      icon: IconCookie,
    },
  ];
  const features = cardData.map((feature) => (
    <Card
      key={feature.title}
      shadow="md"
      radius="md"
      className={classes.card}
      padding="xl"
    >
      <feature.icon size={rem(50)} stroke={2} color={theme.fn.primaryColor()} />
      <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text fz="sm" c="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));

  return (
    <Container ref={learnMoreRef} size="lg" py="xl">
      <Group position="center">
        <Badge variant="filled" size="lg">
          {messages.features_header_simple_label}
        </Badge>
      </Group>

      <Title order={2} className={classes.title} ta="center" mt="sm">
        {messages.features_header_title_label}
      </Title>

      <Text className={classes.description} ta="center" mt="md">
        {messages.features_description_label}
      </Text>

      <SimpleGrid
        cols={3}
        spacing="xl"
        mt={50}
        breakpoints={[{ maxWidth: "md", cols: 1 }]}
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}
