import {
  createStyles,
  Overlay,
  Container,
  Title,
  Button,
  Text,
  rem,
  Group,
  Anchor,
} from "@mantine/core";

import HeroImage from "../../assets/HeroImage.jpg";
import { Translations } from "../../utils/translations";

const useStyles = createStyles((theme) => ({
  hero: {
    position: "relative",
    backgroundImage: `url(${HeroImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingTop: "60px",
  },

  container: {
    height: rem(700),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    paddingBottom: `calc(${theme.spacing.xl} * 6)`,
    zIndex: 1,
    position: "relative",

    [theme.fn.smallerThan("sm")]: {
      height: rem(500),
      paddingBottom: `calc(${theme.spacing.xl} * 3)`,
    },
  },

  title: {
    color: theme.white,
    fontSize: rem(60),
    fontWeight: 900,
    lineHeight: 1.1,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(40),
      lineHeight: 1.2,
    },

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      lineHeight: 1.3,
    },
  },

  description: {
    color: theme.white,
    maxWidth: 600,

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
      fontSize: theme.fontSizes.sm,
    },
  },

  control: {
    marginTop: `calc(${theme.spacing.xl} * 1.5)`,

    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },
}));

const HeroContentLeft = (props: {
  scrollIntoView: ({ alignment }?: any) => void;
  messages: Translations;
}) => {
  const { messages, scrollIntoView } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.hero}>
      <Overlay
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
        opacity={1}
        zIndex={0}
      />
      <Container className={classes.container}>
        <Title className={classes.title}>{messages.find_your_new_home}</Title>
        <Text className={classes.description} size="xl" mt="xl">
          {messages.mission_stmt}
        </Text>
        <Group w={"100%"}>
          <Anchor w={"100%"} href="/search">
            <Button
              variant="gradient"
              size="md"
              w={"100%"}
              radius="lg"
              className={classes.control}
            >
              {messages.viewAllLabel}
            </Button>
          </Anchor>
          <Anchor w={"100%"} href="/add-property">
            <Button
              variant="gradient"
              size="md"
              w={"100%"}
              radius="lg"
              className={classes.control}
            >
              {messages.routeLabel}
            </Button>
          </Anchor>
          <Button
            variant="gradient"
            size="md"
            w={"100%"}
            radius="lg"
            className={classes.control}
            onClick={() => {
              scrollIntoView({
                alignment: "center",
              });
            }}
          >
            {messages.learn_more_label}
          </Button>
        </Group>
      </Container>
    </div>
  );
};

export { HeroContentLeft };
