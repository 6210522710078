import { Text, Title } from "@mantine/core";
import { Translations } from "../../utils/translations";

const Conformation = (props: { messages: Translations }) => {
  const { messages } = props;
  return (
    <>
      <Title pb={20}>One Step Closer</Title>
      <Text>
        {messages.after_submit_part_1}
        <br /> <br />
        {messages.after_submit_part_2}
        <br /> <br />
        {messages.after_submit_part_3}
        <br /> <br />
        {messages.after_submit_part_4}
        <br /> <br />
      </Text>
    </>
  );
};

export { Conformation };
