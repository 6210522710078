import { Text, Title } from "@mantine/core";

const TermsOfService = () => {
  return (
    <>
      <Title pb={20}>Selleda Terms of Service</Title>
      <Text>
        These Add a Property Terms of Service ("Terms") apply to any individual
        or entity who uses the submission feature (the "Property Submission
        Service") to submit a rental on Selleda (the "Site"). These Terms are in
        addition to the Selleda Terms of Service specified in the Terms of
        Service, which together form a legally binding contract between you and
        Selleda. By using the Property Submission Service to advertise on the
        Site, you represent and warrant that:
        <br /> <br />
        <b>(a)</b> the property is eligible to be submitted using the Property
        Submission Service, meaning the property is an apartment, condo,
        townhome, or single family home and is for rent, not a sublease for a
        unit in a multi-family apartment community;
        <br /> <br />
        <b>(b)</b> you own or have the right to advertise the property you are
        submitting;
        <br /> <br />
        <b>(c)</b> you have the right to lease the advertised property;
        <br /> <br />
        <b>(d)</b> you are willing to lease the advertised property at the price
        and on any other terms specified in your submission;
        <br /> <br />
        <b>(e)</b> you will not advertise more than one property in a single
        submission on the Property Submission Service, either by including
        descriptions of multiple units in one property or by subsequently
        updating the property information to advertise different properties or
        units;
        <br /> <br />
        <b>(f)</b> upon leasing or otherwise taking your property off the
        market, you will delete the information for that property within 72
        hours;
        <br /> <br />
        <b>(g)</b> you will not use the Property Submission Service to advertise
        or promote anything, including, without limitation, any product,
        service, or business, other than the unique property offered for lease;
        <br /> <br />
        <b>(h)</b> you will not use the Property Submission Service to advertise
        shared housing, i.e., a room or rooms within a unit;
        <br /> <br />
        <b>(i)</b> you will not use the Property Submission Service or the Site
        for any fraudulent, false, misleading, or deceptive activity;
        <br /> <br />
        <b>(j)</b> all Content that you provide to Selleda and everything in
        your submission, including without limitation, any photos or text
        description, is truthful, accurate, and complete and complies with all
        local, state, and federal laws and regulations, including without
        limitation, fair housing laws;
        <br /> <br />
        <b>(k)</b> you are solely responsible for any and all submissions you
        add using the Property Submission Service, and all ads, photos,
        descriptions, data, or other information ("Content") you submit to the
        Site;
        <br /> <br />
        <b>(l)</b> you have all the rights necessary to post your property using
        the Property Submission Service, including, without limitation, all
        content, photos, data, text, and other materials contained therein, on
        the Site;
        <br /> <br />
        <b>(m)</b> photos you upload to the Property Submission Service will
        only show the property submitted for rent and shall not include, under
        any circumstances, people, animals, trademarked items, religious
        symbols, or other objects or features not part of the property or
        property community.
        <br /> <br />
        By submitting a property via the Property Submission Service, you grant
        to Selleda, its affiliates, and its parent and related companies, a
        perpetual and irrevocable license to copy, modify, display, distribute,
        create derivative works from, or otherwise use and exploit the Content
        that you submit. The permission you grant Selleda includes express
        permission to syndicate the property submission and Content to
        affiliated or partner sites. Selleda reserves the right, in its sole
        discretion, to remove any submission or Content from the Property
        Submission Service, without notice and without liability, that does not
        meet the requirements of these Add a Property Terms of Service, the
        Site's Terms of Service, Selleda's policies, or if Selleda otherwise
        determines or believes, in its sole and exclusive discretion, is
        inappropriate for any legal, business, technical, or other reason.
        <br /> <br />
        LIQUIDATED DAMAGES. Violations of these Add a Property Terms of Service
        harm the integrity, functionality, and reputation of Selleda; detract
        from users' and our customers' trust in and use of the Site; and damage
        the business of Selleda. Accordingly, you acknowledge and agree that
        Selleda incurs actual damages, which are extremely difficult and/or
        impossible to quantify, as a result of any violation of these Add a
        Property Terms of Service. You agree that it is fair and reasonable for
        Selleda, at its option, to recover liquidated damages for past
        violations in the amount specified below, which represents a reasonable
        estimate of the actual damages incurred by Selleda for each such
        violation you commit and to seek and obtain injunctive relief to prevent
        future violations. You agree that monetary damages alone would not
        provide a sufficient remedy to Selleda for violations of these Add a
        Property Terms of Service, and you consent to injunctive or other
        equitable relief for such violations without the requirement that
        Selleda post a bond. You agree specifically that the availability of
        liquidated damages does not impact Selleda's right to obtain injunctive
        relief. You further acknowledge and agree that the imposition of
        liquidated damages for each such violation of the Terms is independent
        and distinct, and that the assessment of total liquidated damages for
        multiple violations of the Terms is cumulative.
        <br /> <br />
        $10,000 Per Submission. You agree to pay $50,000 per advertisement on
        the Site that contains false or fraudulent content (including but not
        limited to false or misleading property location, descriptions, photos,
        or contact information).
        <br /> <br />
        GENERAL LIMITATIONS. In most cases, properties submitted using the
        Property Submission Service will appear on the Site within 24 hours of
        submission. SELLEDA DOES NOT MAKE AND EXPRESSLY DISCLAIMS ANY AND ALL
        REPRESENTATIONS AND WARRANTIES TO YOU REGARDING THE PROPERTY SUBMISSION
        SERVICE, INCLUDING, WITHOUT LIMITATION, ANY REPRESENTATIONS OR
        WARRANTIES THAT YOU WILL LEASE YOUR PROPERTY, OBTAIN AN ACCEPTABLE RENT
        FOR YOUR PROPERTY, ONLY RECEIVE LEGITIMATE INQUIRIES OR SOLICITATIONS
        FROM QUALIFIED RENTERS, OR RECEIVE ANY INQUIRIES REGARDING YOUR PROPERTY
        FOR RENT. Selleda reserves the right to, but is under no obligation to
        and does not assume any obligation to, review submissions added using
        the Property Submission Service before and/or after they are posted to
        the Site to confirm information relating to the advertiser and the
        advertised property and to investigate complaints and suspicious
        activity. If Selleda determines, in its sole and exclusive discretion,
        that you are engaging in any unfair, deceptive, false, misleading, or
        fraudulent practices, or that your submission violates any laws or
        guidelines, Selleda reserves the right to reject or immediately remove
        your submission from the Site without notice and without liability, as
        described above.
        <br /> <br />
        <b>Miscellaneous</b>
        <br /> <br />
        These Add a Property Terms of Service may be changed at any time, and
        you will be notified of any such changes by an updated posting of the
        new Terms on this page of the Site. Your continued use of the Property
        Submission Service or the Site after the posting of any revised Terms
        shall constitute your agreement to be bound by any such changes. Selleda
        may modify, suspend, discontinue, or restrict the use of any portion of
        the Property Submission Service or the Site without notice or liability.
        Selleda's parent is headquartered in the District of Columbia. These
        Terms will be governed by and construed in accordance with the laws of
        the District of Columbia without regard to its conflicts of law
        provisions. You hereby agree that any cause of action you may have with
        respect to the Property Submission Service or the Site must be
        exclusively filed in the federal or state courts located in the District
        of Columbia, within one (1) year after the cause of action arises or the
        cause is barred. You hereby consent to personal jurisdiction in the
        federal and state courts in the District of Columbia and waive any
        objection based on forum non conveniens. As a condition of using the
        Property Submission Service and the Site, you agree that all causes of
        action arising
      </Text>
    </>
  );
};

export { TermsOfService };
