import { allStates } from "../Types/FormTypes";

export const stateCodeMapper = (state: string) => {
  const result = allStates.find((item) => item.label === state);
  return result?.value ? result?.value : "";
};

export const codeToStateMapper = (state: string) => {
  const result = allStates.find((item) => item.value === state);
  return result?.label ? result?.label : "";
};
