import axios from "axios";

export const sendRegistrationForm = async (form: string) => {
  const data = await fetch(`${process.env.REACT_APP_HOST_URL}/prod/submit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ form }),
  });
  return console.log(data.status);
};

export const sendImages = async (files: any) => {
  axios.post(`${process.env.REACT_APP_HOST_URL}/prod/postimage`, files, {
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      // "Access-Control-Allow-Origin": "*",
    },
  });
};

export const uploadFile = (fileName: string, fileToUpload: any) => {
  console.log("Uploading file ", fileName);
  // Getting the signed url
  axios(
    `${process.env.REACT_APP_HOST_URL}/prod/postimage?fileName=` + fileName
  ).then((response) => {
    // Getting the url from response
    const url = response.data.fileUploadURL;

    axios({
      method: "PUT",
      url: url,
      data: fileToUpload,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        console.log("SUCCESS UPLOADING FILE", fileName);
      })
      .catch((err) => {
        console.log("Error Occured while uploading the file", fileName);
      });
  });
};
