import {
  createStyles,
  Text,
  Title,
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
  ActionIcon,
  rem,
} from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandFacebook,
  IconBrandInstagram,
  IconCloudUpload,
  IconX,
} from "@tabler/icons-react";
import { notifications, Notifications } from "@mantine/notifications";

import { useEffect, useState } from "react";
import { Translations } from "../../utils/translations";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 400,
    boxSizing: "border-box",
    backgroundImage: `linear-gradient(-60deg, ${
      theme.colors[theme.primaryColor][4]
    } 0%, ${theme.colors[theme.primaryColor][7]} 100%)`,
    borderRadius: theme.radius.md,
    padding: `calc(${theme.spacing.xl} * 2.5)`,

    [theme.fn.smallerThan("sm")]: {
      padding: `calc(${theme.spacing.xl} * 1.5)`,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.white,
    lineHeight: 1,
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    maxWidth: rem(300),

    [theme.fn.smallerThan("sm")]: {
      maxWidth: "100%",
    },
  },

  form: {
    backgroundColor: theme.white,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,
  },

  social: {
    color: theme.white,

    "&:hover": {
      color: theme.colors[theme.primaryColor][1],
    },
  },

  input: {
    backgroundColor: theme.white,
    borderColor: theme.colors.gray[4],
    color: theme.black,

    "&::placeholder": {
      color: theme.colors.gray[5],
    },
  },

  inputLabel: {
    color: theme.black,
  },

  control: {
    backgroundColor: theme.colors[theme.primaryColor][6],
  },
}));

const social = [IconBrandTwitter, IconBrandFacebook, IconBrandInstagram];

function ContactUs(props: { messages: Translations }) {
  const { classes } = useStyles();
  const { messages } = props;
  const [form, setForm] = useState({ email: "", name: "", desc: "" });
  const [formStatus, setFormStatus] = useState(0);
  const icons = social.map((Icon, index) => (
    <ActionIcon
      key={index}
      size={28}
      className={classes.social}
      variant="transparent"
    >
      <Icon size="1.4rem" stroke={1.5} />
    </ActionIcon>
  ));
  useEffect(() => {
    if (formStatus === 200) {
      notifications.update({
        id: "send-feedback",
        icon: <IconCloudUpload size="1rem" />,
        title: "Sent",
        message: messages.contact_us_after_submission,
        autoClose: true,
        withCloseButton: true,
      });
    } else if (formStatus !== 9) {
      notifications.update({
        id: "send-feedback",
        loading: false,
        color: "red",
        title: "Upload Failed",
        message: "Failed to send your inquiry. Please try again.",
        icon: <IconX size="1rem" />,
        autoClose: 20000,
        withCloseButton: true,
      });
    }
  }, [formStatus]);
  const sendHandler = () => {
    const formToSend = JSON.stringify(form);
    fetch(`${process.env.REACT_APP_HOST_URL}/prod/submit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ form: formToSend }),
    })
      .then((response) => {
        setFormStatus(200);
        setForm({ email: "", name: "", desc: "" });
      })
      .catch((error) => {
        setFormStatus(500);
        setForm({ email: "", name: "", desc: "" });
        console.log(error);
      });
  };

  return (
    <div className={classes.wrapper}>
      <Notifications />
      <SimpleGrid
        cols={2}
        spacing={50}
        breakpoints={[{ maxWidth: "sm", cols: 1 }]}
      >
        <div>
          <Title className={classes.title}>{messages.contact_us}</Title>
          <Text className={classes.description} mt="sm" mb={30}>
            {messages.contact_us_description}
          </Text>

          <Group mt="xl">{icons}</Group>
        </div>
        <div className={classes.form}>
          <TextInput
            label={messages.email}
            placeholder={messages.contact_us_placeholder}
            required
            onChange={(event) =>
              setForm({ ...form, email: event.currentTarget.value })
            }
            value={form.email}
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <TextInput
            label={messages.contact_us_name}
            placeholder={messages.contact_us_name_placeholder}
            mt="md"
            onChange={(event) =>
              setForm({ ...form, name: event.currentTarget.value })
            }
            value={form.name}
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />
          <Textarea
            required
            label={messages.contact_us_message}
            placeholder={messages.contact_us_message_placeholder}
            minRows={4}
            onChange={(event) =>
              setForm({ ...form, desc: event.currentTarget.value })
            }
            value={form.desc}
            mt="md"
            classNames={{ input: classes.input, label: classes.inputLabel }}
          />

          <Group position="right" mt="md">
            <Button
              onClick={() => {
                sendHandler();
                notifications.show({
                  id: "send-feedback",
                  loading: true,
                  icon: <IconCloudUpload size="1rem" />,
                  title: "Sending Feedback",
                  message: "Please wait while we submit your inquiry",
                  autoClose: false,
                  withCloseButton: false,
                });
              }}
              className={classes.control}
            >
              {messages.contact_us_send_button}
            </Button>
          </Group>
        </div>
      </SimpleGrid>
    </div>
  );
}

export { ContactUs };
