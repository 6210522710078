import {
  Card,
  Image,
  Text,
  Badge,
  Group,
  Spoiler,
  Button,
} from "@mantine/core";
import { Carousel } from "../Carousel";
import PlaceholderImage from "../../assets/placeholder.jpg";
import { FormFields } from "../../Types/FormTypes";
import {
  IconMap2,
  IconUsersGroup,
  IconBed,
  IconPhoneCall,
  IconMailbox,
  IconCurrencyDollar,
  IconHomeLink,
  IconUser,
  IconListDetails,
  IconCalendarTime,
} from "@tabler/icons-react";
import { Translations } from "../../utils/translations";

const PropertyCardV2 = (
  props: FormFields & {
    gallery: { original: string; thumbnail: string }[];
    id: string;
    timestamp: string;
    isOnPropertyForm?: boolean;
    messages: Translations;
  }
) => {
  const {
    address,
    state,
    phoneNumber,
    email,
    description,
    rooms,
    propertyType,
    price,
    imageFiles,
    isOnPropertyForm,
    gallery,
    id,
    timestamp,
    messages,
  } = props;
  const images: { original: string; thumbnail: string }[] =
    gallery.length > 0
      ? gallery
      : imageFiles.map((image) => {
          return {
            original: URL.createObjectURL(image),
            thumbnail: URL.createObjectURL(image),
          };
        });

  const mailTo = email ? `mailto:${email}` : undefined;
  return (
    <Card
      sx={{
        boxShadow:
          "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
        overflow: "visible",
      }}
      bg={"#f2f2f2"}
      maw={360}
      miw={300}
      padding="lg"
      radius="md"
      m={"md"}
      withBorder
    >
      <Card.Section>
        {images && images?.length > 0 ? (
          <Carousel images={images} />
        ) : (
          <Carousel
            images={[
              { original: PlaceholderImage, thumbnail: PlaceholderImage },
            ]}
          />
        )}
      </Card.Section>

      <Group position="center" mt="md">
        {state && (
          <Badge
            size="lg"
            leftSection={<IconMap2 size="1.1rem" />}
            variant="gradient"
            gradient={{ from: "#c31432", to: "#240b36" }}
          >
            {state}
          </Badge>
        )}
        {propertyType && (
          <Badge
            leftSection={
              propertyType === "Shared" ? (
                <IconUsersGroup size="1.1rem" />
              ) : (
                <IconUser size="1.4rem" />
              )
            }
            variant="gradient"
            size="lg"
            gradient={{ from: "#000000", to: "#0f9b0f" }}
          >
            {propertyType}
          </Badge>
        )}
        {rooms !== 0 && (
          <Badge
            leftSection={<IconBed size="1.1rem" />}
            variant="gradient"
            size="lg"
            gradient={{ from: "#360033", to: "#0b8793" }}
          >
            {rooms}
          </Badge>
        )}
      </Group>

      <Card.Section p="md">
        <>
          {price !== undefined && price >= 0 ? (
            <Badge
              variant="filled"
              radius="xl"
              color="teal"
              size="xl"
              leftSection={<IconCurrencyDollar size="1.4rem" />}
              sx={{
                position: "absolute",
                top: "-20px",
                right: "-12px",
                justifyContent: "center",
                ".mantine-Badge-leftSection": {
                  marginRight: "0px",
                },
              }}
            >
              <Group
                sx={{
                  gap: 0,
                }}
              >
                <Text color="#white" fz="16px" fw={700}>
                  {price}
                </Text>
              </Group>
            </Badge>
          ) : (
            <Badge
              variant="filled"
              radius="xl"
              color="teal"
              size="xl"
              leftSection={<IconCurrencyDollar size="1.4rem" />}
              sx={{
                position: "absolute",
                top: "-20px",
                right: "-12px",
                justifyContent: "center",
                ".mantine-Badge-leftSection": {
                  marginRight: "0px",
                },
              }}
            >
              <Group
                sx={{
                  gap: 0,
                }}
              >
                <Text color="#white" fz="14px" fw={700}>
                  {messages.call_for_price}
                </Text>
              </Group>
            </Badge>
          )}
          <Group
            sx={{
              gap: 0,
              flexWrap: "nowrap",
              paddingBottom: "4px",
              justifyContent: "right",
            }}
          >
            <IconCalendarTime size="1.1rem" />
            <Text pl="xs" fz="sm" fw={400}>
              {timestamp}
            </Text>
          </Group>
        </>

        {address && (
          <Group
            sx={{
              gap: 0,
              flexWrap: "nowrap",
            }}
          >
            <IconHomeLink size="1.4rem" />
            <Text pl="xs" fz="md" fw={500}>
              {address}
            </Text>
          </Group>
        )}

        <Spoiler
          sx={{
            display: "flex",
            flexDirection: "column",
            ".mantine-Spoiler-control": {
              backgroundColor: "#026b18",
              borderRadius: "8px",
              color: "#fff",
              marginTop: "10px",
            },
          }}
          maxHeight={0}
          showLabel={messages.show_more}
          hideLabel={messages.show_less}
        >
          {description && (
            <Text pt={20} size="sm" fw={500}>
              {description}
            </Text>
          )}

          <Group pt={20} pb={20}>
            {phoneNumber && (
              <Button
                component="a"
                href={`tel:${phoneNumber}`}
                leftIcon={<IconPhoneCall size="1.1rem" />}
                fullWidth
                color="dark"
                variant="filled"
              >
                {phoneNumber}
              </Button>
            )}

            {mailTo && (
              <Button
                component="a"
                href={mailTo}
                variant="filled"
                color="dark"
                fullWidth
                leftIcon={<IconMailbox size="1.1rem" />}
              >
                {email}
              </Button>
            )}
            {isOnPropertyForm === false && (
              <Button
                component="a"
                href={`/property/${id}`}
                variant="filled"
                fullWidth
                leftIcon={<IconListDetails size="1.1rem" />}
              >
                {messages.view_more}
              </Button>
            )}
          </Group>
        </Spoiler>
      </Card.Section>
    </Card>
  );
};

export { PropertyCardV2 };
