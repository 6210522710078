export interface Translations {
  shared: string;
  private: string;
  starting_price: string;
  maximum_price: string;
  type: string;
  filters: string;
  state: string;
  email: string;
  address: string;
  address_description: string;
  phone_number: string;
  price: string;
  price_description: string;
  bedroom: string;
  bedrooms: string;
  rental_description: string;
  drag_drop: string;
  image_upload_desc: string;
  success_modal_text: string;
  success_modal_header: string;
  fail_modal_head: string;
  fail_modal_sub_header: string;
  fail_modal_text: string;
  try_again: string;
  form_subheadline: string;
  form_headline: string;
  submit: string;
  no_results: string;
  no_results_filter: string;
  next: string;
  prev: string;
  less_than_max_err: string;
  more_than_min_err: string;
  image_err: string;
  routeLabel: string;
  viewAllLabel: string;
  homePageLinkLabel: string;
  comingSoon: string;
  new_Features: string;
  advanced_filters: string;
  additional_lang: string;
  detail_pg: string;
  feature_enhancement: string;
  new_services: string;
  much_more: string;
  states: string[];
  first_step_label: string;
  first_step_description: string;
  second_step_label: string;
  second_step_description: string;
  third_step_label: string;
  third_step_description: string;
  fourth_step_label: string;
  fourth_step_description: string;
  property_description_label: string;
  property_description_placeholder: string;
  property_description_desc: string;
  state_dropdown_desc: string;
  no_results_state: string;
  rooms_dropdown_desc: string;
  property_type_desc: string;
  failed_post_notification: string;
  mission_stmt: string;
  find_your_new_home: string;
  learn_more_label: string;
  features_description_label: string;
  features_header_simple_label: string;
  features_header_title_label: string;
  features_body_perf_title: string;
  features_body_privacy_title: string;
  features_body_integrated_title: string;
  features_body_perf_label: string;
  features_body_privacy_label: string;
  features_body_integrated_label: string;
  faq_title: string;
  faq_adding_listing_title: string;
  faq_adding_listing_step_1: string;
  faq_adding_listing_step_2: string;
  faq_adding_listing_step_3: string;
  faq_adding_listing_step_4: string;
  faq_adding_listing_step_5: string;
  faq_adding_listing_step_6: string;
  faq_delete_title: string;
  faq_delete_body: string;
  faq_image_limit_title: string;
  faq_image_limit_body: string;
  faq_privacy_title: string;
  faq_privacy_body: string;
  faq_cost_title: string;
  faq_cost_body: string;
  contact_info: string;
  contact_us: string;
  contact_us_description: string;
  contact_us_placeholder: string;
  contact_us_name: string;
  contact_us_name_placeholder: string;
  contact_us_message: string;
  contact_us_message_placeholder: string;
  contact_us_after_submission: string;
  contact_us_send_button: string;
  form_email_required: string;
  form_email_invalid: string;
  form_phone_required: string;
  form_phone_invalid: string;
  form_price_required: string;
  form_address_required: string;
  form_state_required: string;
  form_description_max_words: string;
  form_checkbox_required: string;
  form_rooms_required: string;
  form_property_type_required: string;
  form_description_required: string;
  next_page: string;
  form_go_to_home: string;
  after_submit_part_1: string;
  after_submit_part_2: string;
  after_submit_part_3: string;
  after_submit_part_4: string;
  view_more: string;
  show_more: string;
  show_less: string;
  call_for_price: string;
  share_link: string;
}

const lang_en = {
  shared: "Shared",
  private: "Private",
  starting_price: "Starting Price",
  maximum_price: "Maximum Price",
  type: "Type",
  filters: "Filters",
  state: "State",
  email: "Email",
  address: "Address",
  address_description:
    'it can be an exact or a relative address. Eg. "near university blvd and flower ave"',
  phone_number: "Phone Number",
  price: "Price",
  price_description: "Price per month",
  bedroom: "Bedroom",
  bedrooms: "Bedrooms",
  rental_description: "Rental Description",
  drag_drop: "Drag and drop or click to upload up to 7 images",
  image_upload_desc:
    "Attach upto 7 images and each image should not exceed 8 MegaBytes.",
  success_modal_text:
    "Thank you for your business, your trust, and your confidence. It is our pleasure to work with you. An email has been sent detailing the next steps on how to finalize your listing. You will be redirected to the home page in 15 seconds. We look forward to hearing from you. Cheers!",
  success_modal_header: "You're Almost There!",
  fail_modal_head: "OOPS!!",
  fail_modal_sub_header: "Something Went Wrong",
  fail_modal_text:
    " Upload unsuccessful. Try changing a few things and try again. sorry for the inconvinence. this error instance has been reported to our Dev team and we will work on resolving it.",
  try_again: "Try Again",
  form_subheadline:
    "Please Fill out this form to add your rental advertisement.",
  form_headline: "Advertisement form",
  submit: "Submit",
  no_results: "No Results Found",
  no_results_filter: "Try clearing out some of the filters.",
  next: "Next",
  next_page: "Next Page",
  prev: "Prev",
  less_than_max_err: "Should Be Less Than Max Price",
  more_than_min_err: "Should Be Greater Than Starting Price",
  image_err: "You can only upload a maximum of 7 files",
  routeLabel: "Add Your Listing",
  viewAllLabel: "View All Properties",
  homePageLinkLabel: "Home",
  comingSoon: "Coming Soon!",
  new_Features: "New Features Coming Soon",
  advanced_filters: "Advanced filters",
  additional_lang: "Additional languages",
  detail_pg: "Standalone property detail page",
  feature_enhancement: "Feature enhancements",
  new_services: "New Services",
  much_more: "And much much more...",
  states: ["Maryland", "Washington DC", "Virginia"],
  first_step_label: "Contact Information",
  first_step_description: "Fill out contact information",
  second_step_label: "Property details",
  second_step_description: "Please fill out the property details",
  third_step_label: "Upload Images",
  third_step_description: "Attach up to 7 images for your listing.",
  fourth_step_label: "confirmation",
  fourth_step_description:
    "Please review and verify all the provided information.",
  property_description_label: "Property Description",
  property_description_placeholder: "cozy apartment in the heart of dc...",
  property_description_desc: "Additional details you want the renter to know",
  state_dropdown_desc:
    "Please indicate the state in which you would like to list your property.",
  no_results_state:
    "Regrettably, no results were found based on the provided criteria.",
  rooms_dropdown_desc:
    "Please specify the number of bedrooms for your listing.",
  property_type_desc:
    "Please indicate the occupancy status of the listed apartment: whether it is intended for private use or shared accommodation with other individuals.",
  failed_post_notification:
    "Please try again. For further assistance, please feel free to contact Selledateam@gmail.com. We apologize for any inconvenience this may have caused and appreciate your understanding.",
  mission_stmt:
    "Exhausted from the laborious task of manually searching through listings using traditional methods? Look no more — Selleda is at your service. Selleda is the first of its kind with integrated photo support and verbose descriptions all in one place. For a limited time only, all features are offered completely free of charge.",
  find_your_new_home: "Find Your New Home",
  learn_more_label: "Learn More",
  features_description_label:
    "Crafted to streamline and accelerate the rental search journey, Selleda offers a hassle-free method for listing your rentals. Our platform boasts integrated image support, ensuring your listings are visually compelling. The process of updating or removing your listings is seamless and efficient. We are committed to ongoing enhancements, regularly introducing novel features and services. Our dedication to continuous improvement guarantees an optimal experience for all users",
  features_header_simple_label: "Your new home awaits",
  features_header_title_label: "Rental Search Simplified",
  features_body_perf_title: "High Performance",
  features_body_privacy_title: "Privacy Focused",
  features_body_integrated_title: "Integrated Support",
  features_body_perf_label:
    "Selleda is meticulously crafted for optimal performance and scalability, with a strong emphasis on security. We are dedicated to delivering a service that is consistently available, aimed for ensuring a seamless user experience.",
  features_body_privacy_label:
    "At Selleda, safeguarding your privacy is our utmost concern. We operate with the highest level of confidentiality, ensuring that your data remains exclusively within our ecosystem. Your information is transmitted securely to our private servers, and once your tenant search is concluded, all data will be entirely and irreversibly removed from our systems. Rest assured, we never share your data with any third-party services.",
  features_body_integrated_label:
    "We are committed to promptly address your inquiries. For support, You can get in touch with us at selledateam@gmail.com for any questions or general inquiries you may have",

  faq_title: "Frequently Asked Questions",
  faq_adding_listing_title: "How do i add a listing?",
  faq_adding_listing_step_1: "Click on add listing up top",
  faq_adding_listing_step_2: "Fill out the form step by step.",
  faq_adding_listing_step_3:
    "Provide pictures showcasing your property. (This can enhance tenant engagement)",
  faq_adding_listing_step_4:
    "Preview your listing before and verify that the information you provided is accurate before the final submission.",
  faq_adding_listing_step_5:
    "Submit once done and wait for an email with further instructions and a confirmation number.",
  faq_adding_listing_step_6:
    "That's it. You are all done. Your property will be listed within 24-48 hours after initial submission",
  faq_delete_title: "How do I delete my listing",
  faq_delete_body:
    "Reach out to us at selledateam@gmail.com with your provided confirmation number, and we will take care of the rest.",
  faq_image_limit_title: "How many images can i upload for one listing?",
  faq_image_limit_body:
    "The maximum number of images that can be attached to a listing is 7. Please make sure you are providing high quality images get better engagement.",
  faq_privacy_title: "How is my data stored and protected?",
  faq_privacy_body:
    "At Selleda, safeguarding your privacy is our utmost concern. We operate with the highest level of confidentiality, ensuring that your data remains exclusively within our ecosystem. Your information is transmitted securely to our private servers, and once your tenant search is concluded, all data will be entirely and irreversibly removed from our systems. Rest assured, we never share your data with any third-party services.",
  faq_cost_title: "Are Selleda’s services provided for free?",
  faq_cost_body:
    "We are dedicated to providing an exceptional user experience, and to showcase this commitment, we will be offering our services at no cost for a limited time. During this period, all users can enjoy the benefits of our platform without incurring any charges. Our aim is to ensure your satisfaction before we consider implementing monetization strategies.",
  contact_info: "Contact Information",
  contact_us: "Contact us",
  contact_us_description:
    "Leave your email and message below, and our team will get back to you as soon as possible.",
  contact_us_placeholder: "your@email.com",
  contact_us_name: "Name",
  contact_us_name_placeholder: "John Doe",
  contact_us_message: "Your message",
  contact_us_message_placeholder:
    "I want to change this part of the applicaton",
  contact_us_after_submission:
    "Thank you for your inquiry. A member of the Selleda team will reach out to you within 24 - 48 hours.",
  contact_us_send_button: "Send",
  form_email_required: "Email required",
  form_email_invalid: "Invalid email",
  form_phone_required: "Phone number required",
  form_phone_invalid: "Phone number is invalid",
  form_price_required: "Price is Required",
  form_address_required: "Address is required",
  form_state_required: "State is required",
  form_description_max_words: "Should be less than 150 words",
  form_description_required: "Description of 150 words or less is required",
  form_checkbox_required: "Required to Proceed",
  form_rooms_required: "Bedrooms is required",
  form_property_type_required: "Property type is required",
  form_go_to_home: "Go to Home Page",
  after_submit_part_1:
    "Thank you for choosing Selleda! 🎉 We appreciate your business and the trust you have placed in us. It is our utmost pleasure to have the opportunity to work with you.",
  after_submit_part_2:
    "An email has been sent to your registered email address, providing you with detailed instructions on the next steps to finalize your listing. 📩 Please make sure to check your inbox and follow the outlined guidelines. If you don’t see your email inbox, please check your spam for an email from selledateam@gmail.com.",
  after_submit_part_3:
    "In the meantime, you will be automatically redirected to the home page in 15 seconds. 🏠 If the redirection doesn't occur, you can manually navigate to the home page.",
  after_submit_part_4:
    "We are excited to collaborate with you and are looking forward to your continued partnership. Should you have any questions or need further assistance, please don't hesitate to reach out. Cheers! 🥂",
  view_more: "View details",
  show_more: "Show more",
  show_less: "Show less",
  call_for_price: "Call for Pricing",
  share_link: "Copy Url",
};

const lang_amh = {
  shared: "የጋራ",
  private: "የግል",
  starting_price: "መነሻ ዋጋ",
  maximum_price: "የመጨረሻው ዋጋ",
  type: "ዓይነት",
  filters: "ማጣሪያዎች",
  state: "ግዛት",
  email: "ኢሜይል",
  address: "አድራሻ",
  address_description:
    'ትክክለኛ ወይም አንጻራዊ አድራሻ ሊሆን ይችላል። ለምሳሌ. "በዩኒቨርሲቲ blvd እና flower ave አቅራቢያ"',
  phone_number: "ስልክ ቁጥር",
  price: "ዋጋ",
  price_description: "የቤቱ ወርሃዊ ኪራይ",
  bedroom: "መኝታ ቤት",
  bedrooms: "መኝታ ቤቶች",
  rental_description: "ተጨማሪ መግለጫ",
  drag_drop: "እስከ 7 ምስሎችን ለማስገባት ጎትት እና ጣል ወይም ጠቅ አድርግ",
  image_upload_desc: "እስከ 7 ምስሎችን አያይዝ እና እያንዳንዱ ምስል ከ 8 ሜጋባይት መብለጥ የለበትም።",
  success_modal_text:
    "ለንግድዎ እና ስለ እምነትዎ እናመሰግናለን። ከእርስዎ ጋር መስራት ደስ ብሎናል. ማስታወቂያዎን እንዴት ማጠናቀቅ እንደሚችሉ የሚቀጥሉትን እርምጃዎች የሚገልጽ ኢሜይል ተልኳል። በ15 ሰከንድ ውስጥ ወደ መነሻ ገጽ ይዘዋወራሉ ወይም ከታች ያለውን አረንጓዴ ቁልፍ ይጫኑ። እርስዎን ለመስማት በጉጉት እንጠብቃለን። መልካም ውሎ!",
  success_modal_header: "ማስታወቂያውን ለመለጠፍ ተቃርቧል",
  fail_modal_head: "ወዮዮዮ!!",
  fail_modal_sub_header: "ውይ ችግር መጣ",
  fail_modal_text:
    "የማስታወቂያው መለጠፍ ሂደት  አልተሳካም። መረጃው ትክክል መሆኑን ያረጋግጡ። እንደ ኢሜይል ያሉ ጥቂት ነገሮችን ይለውጡ እና እንደገና ይሞክሩ። ቅጹን እንደገና ለመሙላት እንደገና ይሞክሩ የሚለውን ጠቅ ያድርጉ ይህ ስህተት ለሶፍትዌር ምህንድስና ቡድናችን ሪፖርት ተደርጓል እና ለመፍታት እንሰራለን። እናመሰግናለን።",
  try_again: "እንደገና ይሞክሩ",
  form_subheadline: "እባክዎን የኪራይ ማስታወቂያዎን ለመጨመር ይህንን ቅጽ ይሙሉ።",
  form_headline: "የኪራይ ቅጽ",
  submit: "አስረክብ",
  no_results: "ምንም ማስታወቂያዎች አልተገኙም።",
  no_results_filter: "አንዳንድ ማጣሪያዎችን ለማስወገድ ይሞክሩ።",
  next: "ቀጥሎ",
  next_page: "ቀጣይ ገፅ",
  prev: "የቀድሞ",
  less_than_max_err: "ከከፍተኛው ዋጋ ያነሰ መሆን አለበት።",
  more_than_min_err: "ከመጀመሪያው ዋጋ በላይ መሆን አለበት",
  image_err: "ከ 7 ምስሎች በላይ መስጠት አይችሉም",
  routeLabel: "ማስታውቅያ ይለጥፉ",
  viewAllLabel: "የቤቶች ዝርዝር",
  homePageLinkLabel: "ዋና  ገፅ",
  comingSoon: "በቅርብ ቀን",
  new_Features: "በቅርብ ቀን የሚመጡ አዳዲስ አገልገሎቶች",
  advanced_filters: "ተጨማሪ ማጣሪያዎች",
  additional_lang: "ተጨማሪ ቋንቋዎች",
  detail_pg: "ዝርዝር ራሱን የቻለ ገጽ",
  feature_enhancement: "የአጠቃቀም ማሻሻያዎችን",
  new_services: "አዳዲስ አገልግሎቶች",
  much_more: "ወዘተ",
  states: ["ሜሪላንድ", "ዋሽንግተን ዲሲ", "ቨርጂኒያ"],
  first_step_label: "የመገኛ መረጃ",
  first_step_description: "የመገኛ መረጃ ይሙሉ",
  second_step_label: "ስለ ቤትዎ ማስታወቂያ",
  second_step_description: "ስለ ቤትዎ ማስታወቂያ ይንገሩን",
  third_step_label: "የምስል ማሻሻያ ቦታ",
  third_step_description: "ለማስታወቂያዎ እስከ 7 ምስሎችን ያያይዙ።",
  fourth_step_label: "የማረጋገጫ ቦታ",
  fourth_step_description: "እባክዎ ሁሉንም የቀረበውን መረጃ ያረጋግጡ።",
  property_description_label: "ማብራሪያ",
  property_description_placeholder: "በዲሲ ልብ ውስጥ ምቹ አፓርታማ…",
  property_description_desc: "ተከራይው እንዲያውቅ የሚፈልጓቸው ተጨማሪ ማብራሪያዎች",
  no_results_state: "በተሰጠው መስፈርት መሰረት ምንም ምርጫዎች አልተገኙም",
  state_dropdown_desc: "እባክዎን ግዛት ይምረጡ",
  rooms_dropdown_desc: "እባክዎ ለዝርዝርዎ የመኝታ ቤቶችን ብዛት ይምረጡ።",
  property_type_desc: "ለግል ጥቅም የታሰበ ወይም ከሌሎች ግለሰቦች ጋር የጋራ መኖሪያ መግለጫ።",
  failed_post_notification:
    "እባክዎ ዳግም ይሞክሩ. ለበለጠ እርዳታ፣ እባክዎን Selledateam@gmail.com ን ለማግኘት ነፃነት ይሰማዎ። ይህ ለተፈጠረው ማንኛውም ችግር ይቅርታ እንጠይቃለን እና ግንዛቤዎን እናመሰግናለን።",
  mission_stmt:
    "በተለምዶው አሰራር የሚከራይ ቤት መፈለግ ወይም ማስትዋወቅ ተቸግረዋል? አንግዲያውስ ሰሌዳን አንሆ ብለናል! የሰሌዳ አገልግሎት በምስል እና በዝርዝር መረጃ የተደገፈ የኪራይ ማስታውቅያ መለጠፍን ወይም መፈልግን የሚያስችል ነው:: ይህን አገልግሎት ለደንበኞቻችን ለተወሰነ ግዜ ያለ ምንም ከፍያ ማበርከታችንን ስንገልፅ በደስታ ነው!",
  find_your_new_home: "አዲሱን ቤትዎን ይፈልጉ",
  learn_more_label: "ሰለ ሰሌዳ",
  features_description_label:
    "የኪራይ ፍለጋ ጉዞን ለማቃለል እና ለማፋጠን የተሰራው ሰሌዳ ኪራዮችን ለመፈለግ እና ለማስተዋወቅ ልዩ የሆነ አማራጭን ይሰጣል። የተቀናጀ የምስል ድጋፍን በማማከል ማስታወቂያዎችዎ ለእይታ የሚስቡ መሆናቸውን ያረጋግጣል። በተጨማሪም እንከን የለሽ እና ቀልጣፋ በሆነ መልኩ ማስታወቂያዎችዎን የማዘመን እንዲሁም የማስወገድ ሂደትን ያማከለ አስራርን መሰረት ያደረገ ነው:: በየጊዜው አዳዲስ አገልግሎቶችን በማስተዋወቅ እና ቀጣይነት ያለው ማሻሻያ በማድረግ ለተጠቃሚዎቻቾን ጥሩ አገልግሎት ለመስጠት ቆርጠን ተነስተናል።",
  features_header_simple_label: "አዲሱ ቤትዎ ይጠብቅዎታል",
  features_header_title_label: "ቤት ፍለጋ ተቃሏል",
  features_body_perf_title: "ቀልጣፋ አሰራር",
  features_body_privacy_title: "የመረጃ ሚስጥራዊነት ተኮር",
  features_body_integrated_title: "24/7 የተቀናጀ ድጋፍ",
  features_body_perf_label:
    "ሰሌዳ ለደህንነት ከፍተኛ ትኩረት በመስጠት ለተሻለ አፈጻጸም እና ስኬታማነት በጥንቃቄ የተሰራ ነው። ትልቁ እና ቅድሚያ የምንሰጠው ትኩረት ሚስጥራዊነት ያለው የመረጃ አጠባበቅ ነው። ሰሌዳ እንከን የለሽ የተጠቃሚ ተሞክሮን ለማረጋገጥ እና ቀጣይነት ያለው አገልግሎት ለማቅረብ ያለመ ነው።",
  features_body_privacy_label:
    "የመረጃዎን ደህንነት መጠበቅ ዋና አላማችን ነው። መረጃዎ በስነ-ምህዳራችን ውስጥ ብቻ መቆየቱን በማረጋገጥ ከፍተኛውን የመረጃ ሚስጥራዊነት ደረጃ ይዘን እንሰራለን። መረጃዎ ደህንነቱ በተጠበቀ ሁኔታ ወደ የግል አገልጋዮቻችን ይተላለፋል:: በመቀጠልም የተከራይ ፍለጋዎ እንደተጠናቀቀ፣ ሁሉም መረጃዎች ሙሉ በሙሉ እና በማይቀለበስ ሁኔታ ከስነ-ምህዳራችን ይወገዳሉ። መረጃዎን ከማንኛውም የሶስተኛ ወገን አገልግሎቶች ጋር አናጋራም።",
  features_body_integrated_label:
    "ጥያቄዎችዎን በፍጥነት ለመመለስ እንሰራለን። ለማንኛውም ጥያቄ ወይም ድጋፍ selledateam@gmail.com ላይ ይፃፉልን::",
  faq_title: "በተደጋጋሚ የሚነሱ ጥያቄዎች",
  faq_adding_listing_title: "ማስታወቂያ እንዴት መለጠፍ እችላለሁ?",
  faq_adding_listing_step_1: 'ከላይ "ማስታውቅያ ይለጥፉ" የሚለውን ይጫኑ',
  faq_adding_listing_step_2: "ቅጹን ሙሉ በሙሉ ይሙሉ",
  faq_adding_listing_step_3:
    "የኪራይ ቤትዎን የሚያሳዩ ምስሎችን ያቅርቡ። (ይህ የተከራይ ተሳትፎን ሊያሻሽል ይችላል)",
  faq_adding_listing_step_4:
    "የቀረበው መረጃ ትክክለኛ መሆኑን ያረጋግጡ እና ከመጨረስዎ በፊት ማስታወቂያዎን አስቀድመው ይመልከቱ።",
  faq_adding_listing_step_5:
    "እንደጨረሱ ይለጥፉ እና ተጨማሪ መመሪያዎችን እና የማረጋገጫ ቁጥር ያለው ኢሜይል ይጠብቁ።",
  faq_adding_listing_step_6:
    "ማመልከቻዎ ከተገመገመ በኀላ በ24-48 ሰዐት ውስጥ ለተጠቃሚ አይታ ይቀርባል።",
  faq_delete_title: "ማስታወቂያዬን እንዴት ማጥፋት እችላለሁ?",
  faq_delete_body:
    "በተጠቀሰው የኮንፎርሜሽን ቁጥር በ selledateam@gmail.com ያግኙን እና በ24-48 ሰአታት ውስጥ ማስታወቂያውን እናጠፋለን",
  faq_image_limit_title: "ለአንድ ማስታወቂያ ስንት ምስሎችን ማስገባት እችላለሁ?",
  faq_image_limit_body:
    "ለማስታወቂያ መቅረብ የሚችሉ ምስሎች ከፍተኛ ብዛት 7 ሲሆን ለተሻለ አገልግሎት ከፍተኛ ጥራት ያላቸውን ምስሎች ማቅረብዎትን ያረጋግጡ።",
  faq_privacy_title: "መረጃውን ደህንነቱ በተጠበቀ ሁኔታ ይቀመጣል?",
  faq_privacy_body:
    "በሴሌዳ፣ የእርስዎን መረጃ ደህንነት መጠበቅ ከሁሉም በላይ የሚያሳስበን ነው። መረጃዎ በስነ-ምህዳራችን ውስጥ ብቻ መቆየቱን በማረጋገጥ ከፍተኛውን የምስጢርነት ደረጃ ይዘን እንሰራለን። የእርስዎ ማስታወቂያ ደህንነቱ በተጠበቀ ሁኔታ ወደ የግል አገልጋዮቻችን ይተላለፋል፣ እና አንዴ የተከራይ ፍለጋዎ እንደተጠናቀቀ፣ ሁሉም መረጃዎች ሙሉ በሙሉ እና በማይቀለበስ ሁኔታ ከስርዓታችን ይወገዳሉ። እርግጠኛ ይሁኑ፣ የእርስዎን መረጃ ከማናቸውም የሶስተኛ ወገን አገልግሎቶች ጋር አናጋራም።",
  faq_cost_title: "ነፃ ነው?",
  faq_cost_body:
    "የደንበኞቻችን እርካታ እና የላቀ ተጠቃሚነትን ያማከለ አገልግሎት በማቅረብ እና ሂደታዊ ማሻሻያዎችን በማድረግ በሙከራ ጊዜያችን ውስጥ ሁሉንም ወጪ በራሳችን አንሸፈናለን። አላማችን የገቢ መፍጠር ስልቶችን ከመተግበራችን በፊት የደንበኞቻችንን እርካታን አስቀድሞ ማረጋገጥ ነው:: በመሆኑም ለተወሰነ ጊዜ የሰሌዳ አግልግሎቶች የሚሰጡት በነፃ ነው።",
  contact_info: "የመገኛ መረጃ",
  contact_us: "መልዕክትዎ",
  contact_us_description: "ኢሜልዎን ይላኩልን እና በ 24 ሰዓታት ውስጥ እንመልስሎታለን",
  contact_us_placeholder: "ኢሜል@ጂሜል.ኮም",
  contact_us_name: "ስም",
  contact_us_name_placeholder: "አበበ አለሙ",
  contact_us_message: "መልክቶ",
  contact_us_message_placeholder: "ይህንን ማመልከቻ ክፍል መለወጥ እፈልጋለሁ",
  contact_us_after_submission:
    "ለጥያቄዎ እናመሰግናለን። የሴሌዳ ቡድን አባል በ24 - 48 ሰአታት ውስጥ ያገኝዎታል።",
  contact_us_send_button: "ላክ",
  form_email_required: "ኢሜል ያስፈልጋል",
  form_email_invalid: "የተሳሳተ ኢሜይል",
  form_phone_required: "ስልክ ቁጥር ያስፈልጋል",
  form_phone_invalid: "ስልክ ቁጥር ልክ ያልሆነ ነው።",
  form_price_required: "ዋጋ ያስፈልጋል",
  form_address_required: "አድራሻ ያስፈልጋል",
  form_state_required: "ግዛት ያስፈልጋል",
  form_description_max_words: "ከ 150 ቃላት ያነሰ መሆን አለበት",
  form_checkbox_required: "ለመቀጠል የውል ስምምነት ያስፈልጋል",
  form_description_required: "የ150 ቃላት ወይም ከዚያ ያነሰ መግለጫ ያስፈልጋል",
  form_rooms_required: "የመኝታ ክፍሎች ቁጥር ያስፈልጋል",
  form_property_type_required: "የግል ወይም የጋራ መሁኑን መግለፅ ያስፈልጋል",
  form_go_to_home: "ወደ መነሻ ገጽ",
  after_submit_part_1:
    "ሰሌዳን ስለመረጡ እናመሰግናለን። በአገልግሎታችን ላይ ያሳደሩትን እምነት እናደንቃለን። ከእርስዎ ጋር ለመስራት እድሉን ማግኘታችን በጣም ደስ ብሎናል።",
  after_submit_part_2:
    "ዝርዝርዎን ለማጠናቀቅ በሚቀጥሉት ደረጃዎች ላይ ዝርዝር መመሪያዎችን በመስጠት ወደ ተመዝግቦ ኢሜል አድራሻዎ ኢሜል ተልኳል። እባክዎ የመልዕክት ሳጥንዎን ከፍተው የተዘረዘሩትን መመሪያዎች ይከተሉ። የኢሜል መልእክት ካላዩ፣ እባክዎን spam ቼክ ያድርጉ ።",
  after_submit_part_3:
    "እስከዚያው ድረስ በ 15 ሰከንድ ውስጥ ወደ መነሻ ገጽ ይዘዋወራሉ።  ወደ ዋናው ገጽ በ 15 ሰከንድ ውስጥ ካልተመለሱ በእራስዎ ወደ መነሻ ገጹ መሄድ ይችላሉ።",
  after_submit_part_4:
    "ከእርስዎ ጋር ለመተባበር ጓጉተናል እና ቀጣይ አጋርነትዎን በጉጉት እንጠባበቃለን። ማናቸውም ጥያቄዎች ካሉዎት ወይም ተጨማሪ እርዳታ ከፈለጉ እባክዎን ለማግኘት አያመንቱ።",
  view_more: "ሙሉ መግለጫ",
  show_more: "ተጨማሪ ዝርዝር",
  show_less: "አነስተኛ ዝርዝር",
  call_for_price: "ይደውሉ",
  share_link: "ቬር Url",
};

const lang_tig = {
  shared: "ዘርእስታ ርእስታት",
  private: "ብሕታዊ",
  starting_price: "ዋጋ ምጅማር",
  maximum_price: "ዝለዓለ ዋጋ",
  type: "ዓይነት",
  filters: "መጻረዪታት",
  state: "ኣውራጃ",
  email: "ኢ-መይል",
  address: "ኣድራሻ",
  address_description:
    'ልክዕ ወይ ተዛማዲ ኣድራሻ ኪኸውን ይኽእል እዩ ። ኣብነት "ኣብ ጥቓ እቲ ኣብ ጥቓ ዩኒቨርሲቲ ዚርከብ ልሕጺ እንጨይትን ዕምባባታትን"',
  phone_number: "ቍጽሪ ተሌፎን",
  price: "ዋጋ",
  price_description: "እታ ቤት ዋጋ ኣብ ወርሒ",
  bedroom: "መደቀሲ ኽፍሊ",
  bedrooms: "መደቀሲ ኽፍልታት",
  rental_description: "ተወሳኺ መግለጺ",
  drag_drop: "ጐቲትካ ምውዳቕ ወይ ክሳዕ 7 ምስልታት ምውሳኽ",
  success_modal_text:
    "ስለ ዝመረጽኩምና አመስግነኩም ። ነቲ ረክላማትካ ብኸመይ ከም እትውድኦ ዚገልጽ ዚቕጽል ስጕምትታት ዚገልጽ ኢ-መይል ተላእከ። ኣብ ውሽጢ 15 ኻልኢት ናብቲ ናይ መጀመርታ ገጽ ክትምለስ ኢኻ። ካባኻ ኽንሰምዕ ብሃንቀውታ ኢና እንጽበ ።",
  success_modal_header: "You're Almost There!",
  fail_modal_head: "ጸገም!!",
  fail_modal_sub_header: "ዳርጋ ኣብ ምብጻሕና",
  fail_modal_text:
    "ኣራግፍ ዕዉት ኣይኰነን። ሒደት ነገራት ክትቅይር ፈትን ። በቲ ዘይምሹእ ኵነታት ኣይትሓዘለይ። እዚ ጌጋ እዚ ንጕጅለ ምዕባለ ሶፍትዌርና ጸብጻብ ተዋሂቡ ኣሎ ንሕና ድማ ነዚ ንምፍታሕ ክንዓዪ ኢና። ስለቲ ቐጻሊ ትዕግስትኹም አመስግነኩም ።",
  try_again: "ደጊምካ ፈትን",
  form_subheadline: "ረክላማት ክራይካ ምእንቲ ኽትውስኽ በጃኻ ነዚ ፎርም እዚ ምልኣዮ።",
  form_headline: "ፎርም ረክላማት",
  submit: "ኣእቱ",
  no_results: "ዋላ ሓንቲ ፍረ ኣይተረኽበን",
  no_results_filter: "ንገለ ኻብቲ መጻረዪታት ከተልግሶ ፈትን።",
  next: "ቀጺሉ",
  prev: "ቅድሚኡ",
  less_than_max_err: "ካብቲ ዝለዓለ ዋጋ ዝውሕድ ክኸውን ኣለዎ",
  more_than_min_err: "ካብ መጀመርታ ዋጋ ዚዓቢ ኪኸውን ኣለዎ",
  image_err: "ልዕሊ 7 ፋይላት ጥራይ ከተራግፍ ትኽእል ኢኻ",
  routeLabel: "ረክላማትካ ወሲኽካ",
  homePageLinkLabel: "ናይ መጀመርታ ገጽ",
  comingSoon: "ኣብ ቀረባ እዋን!",
  new_Features: "ድሕሪ ሓጺር እዋን ሓድሽ ተወሳኺ ነገራት ይመጽእ ኣሎ!",
  advanced_filters: "ምዕቡል መጻረዪታት",
  additional_lang: "ተወሳኺ ቛንቋታት",
  detail_pg: "ንበይኑ ንብረት ዚገልጽ ዝርዝር ገጽ",
  feature_enhancement: "ምምሕያሻት",
  new_services: "ተወሳኺ ኣገልግሎት",
  much_more: "ካብዚ ንላዕሊ ድማ...",
  states: ["ሜሪላንድ", "ዋሽንግተን ዲሲ", "ቨርጂኒያ"],
  first_step_label: "ሓበሬታ ኣድራሻ",
  first_step_description: "ኣድራሻታት ምልኣ",
  second_step_label: "ዝርዝር ረክላማት ቤት",
  second_step_description: "በጃኻ ብዛዕባ ረክላማት ቤትካ ንገረና",
  third_step_label: "Contact Information",
};

export { lang_amh, lang_en, lang_tig };
