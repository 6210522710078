import React, { useEffect, useState } from "react";
import Image from "@material-tailwind/react/Image";
import googlePlay from "../../assets/googleplay.svg";
import appStore from "../../assets/appstore.svg";
import comingSoon from "../../assets/comingsoon.png";
import { Box, Flex } from "@mantine/core";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTelegram,
  IconBrandTiktok,
  IconBrandTwitter,
} from "@tabler/icons-react";
import { isRunningOnApp } from "../../utils/helpers";

const footerStyleBase: React.CSSProperties = {
  position: "relative",
  bottom: 0,
  width: "100%",
};
const Footer = () => {
  const [isInWebView, setIsInWebView] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;

    // Detect if the user agent indicates a WebView in iOS excluding Chrome for iOS
    const isIOSWebView =
      /iPhone|iPod|iPad/.test(userAgent) && !/Safari/.test(userAgent);
    const isChromeForIOS = /CriOS/.test(userAgent); // Detect Chrome for iOS

    setIsInWebView(isIOSWebView && !isChromeForIOS);
  }, []);

  const footerStyle = !isInWebView
    ? { ...footerStyleBase, height: "220px" }
    : { ...footerStyleBase, height: "250px" };

  return (
    <>
      <footer
        style={footerStyle}
        className="text-center bg-gray-900 text-white"
      >
        {/* <Flex justify={"center"}>
          <Box sx={{ width: "100px", padding: "0" }}>
            <Image src={comingSoon} />
          </Box>
        </Flex> */}
        {!isInWebView && (
          <div className="text-center flex items-center justify-center bg-gray-900 text-white">
            <a
              href="https://play.google.com/store/apps/details?id=co.median.android.dmpeam"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image className="p-4" src={googlePlay} />
            </a>
            <a
              href="https://apps.apple.com/us/app/selleda/id6502376105"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image src={appStore} />{" "}
            </a>
          </div>
        )}

        <div className="px-6">
          <div className="flex justify-center mb-6 mt-6">
            <a
              href="#!"
              type="button"
              className="rounded-full border-2 border-white text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1 flex justify-center items-center"
            >
              <IconBrandFacebook size="1.2rem" />
            </a>
            <a
              href="#!"
              type="button"
              className="rounded-full border-2 border-white text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1 flex justify-center items-center"
            >
              <IconBrandTwitter size="1.2rem" />
            </a>
            <a
              href="#!"
              type="button"
              className="rounded-full border-2 border-white text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1 flex justify-center items-center"
            >
              <IconBrandInstagram size="1.2rem" />
            </a>
            <a
              href="#!"
              type="button"
              className="rounded-full border-2 border-white text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1 flex justify-center items-center"
            >
              <IconBrandTelegram size="1.2rem" />
            </a>
            <a
              href="#!"
              type="button"
              className="rounded-full border-2 border-white text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1 flex justify-center items-center"
            >
              <IconBrandLinkedin size="1.2rem" />
            </a>
            <a
              href="#!"
              type="button"
              className="rounded-full border-2 border-white text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1 flex justify-center items-center"
            >
              <IconBrandTiktok size="1.2rem" />
            </a>
          </div>
        </div>

        <div
          className="text-center p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          © 2023 Copyright: All rights reserved.
        </div>
      </footer>
    </>
  );
};

export { Footer };
