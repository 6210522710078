import React, { useEffect, useState } from "react";
import { Box } from "theme-ui";
import { Grid, TextField, InputAdornment } from "@material-ui/core";

const MIN = -2;
const MAX = 10000;

interface PriceRangeProps {
  setPriceRange: any;
  minLabel: string;
  maxLabel: string;
  less_than_max_err: string;
  more_than_min_err: string;
  handleLoadingState: () => void;
  resetPageCount: () => void;
}

const PriceRange = (props: PriceRangeProps) => {
  const {
    setPriceRange,
    maxLabel,
    minLabel,
    less_than_max_err,
    more_than_min_err,
    handleLoadingState,
    resetPageCount,
  } = props;
  let filterTimeout: NodeJS.Timeout;
  const [rangeValues, setValues] = useState<number[]>([MIN, MAX]);
  const [showMaxErrMsg, setMaxShowErrMsg] = useState(false);
  const [showMinErrMsg, setShowMinErrMsg] = useState(false);

  return (
    <Box sx={{ marginTop: "10px" }}>
      <Grid
        container
        alignItems="center"
        justify="space-around"
        style={{ paddingLeft: "12px", paddingRight: "12px", marginTop: "15px" }}
      >
        <Grid item xs={4} style={{ textAlign: "right" }}>
          <TextField
            variant="outlined"
            label={minLabel}
            type="number"
            error={showMinErrMsg}
            helperText={showMinErrMsg ? less_than_max_err : ""}
            onBlur={(evt) => {
              const value = evt.target.value;
              const minValue = value ? parseInt(value) : MIN;
              if (rangeValues?.[1] && minValue < rangeValues[1]) {
                setShowMinErrMsg(false);
                clearTimeout(filterTimeout);
                handleLoadingState();
                resetPageCount();
                filterTimeout = setTimeout(() => {
                  const newState = [minValue, rangeValues[1]];
                  setValues(newState);
                  setPriceRange({
                    min: newState[0] ?? MIN,
                    max: newState[1] ?? MAX,
                  });
                }, 1500);
              } else {
                setShowMinErrMsg(true);
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={4} style={{ textAlign: "center" }}>
          —
        </Grid>
        <Grid item xs={4} style={{ textAlign: "left" }}>
          <TextField
            variant="outlined"
            label={maxLabel}
            type="number"
            helperText={showMaxErrMsg ? more_than_min_err : ""}
            error={showMaxErrMsg}
            onBlur={(evt) => {
              const value = evt.target.value;
              const maxValue = value ? parseInt(value) : MAX;

              if (rangeValues?.[1] && maxValue >= rangeValues[0]) {
                setMaxShowErrMsg(false);
                clearTimeout(filterTimeout);
                handleLoadingState();
                resetPageCount();
                filterTimeout = setTimeout(() => {
                  const newState = [rangeValues[0], maxValue];
                  setValues(newState);
                  setPriceRange({
                    min: newState[0] ?? MIN,
                    max: newState[1] ?? MAX,
                  });
                }, 1500);
              } else {
                setMaxShowErrMsg(true);
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export { PriceRange };
