import React, { useEffect, useState } from "react";
import { PropertyCardV2 } from "../PropertyCard";
// import { getListings } from "../../utils/requests";
import axios from "axios";
import { CardSkeleton } from "../SkeletonLoader/CardSkeleton";
import { FilterBar } from "../FilterBar";
import ReactPaginate from "react-paginate";
import { Box, Flex, Text } from "theme-ui";
import Image from "@material-tailwind/react/Image";
import NoResults from "../../assets/wowemoji.gif";
import { Translations } from "../../utils/translations";
import { codeToStateMapper } from "../../utils/mapping";

export interface FetchedResults {
  imageFiles: any;
  // isPopular: boolean;
  address: string;
  state: string;
  phoneNumber: string;
  email: string;
  productDescription: string;
  rooms: number;
  id: string;
  propertyType: string;
  postedDate: string;
  starRating: number;
  price?: number;
  images: {
    original: string;
    thumbnail: string;
  }[];
}
interface PropertiesContainerProps {
  messages: Translations;
}
const PER_PAGE = 9;

const PropertiesContainer = (props: PropertiesContainerProps) => {
  const { messages } = props;
  const [data, setData] = useState<FetchedResults[]>([]);
  const [currStates, setCurrStates] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [stateSelected, setstateSelected] = useState<string[]>([]);
  const [propertyTypeSelected, setPropertyTypeSelected] = useState([
    "Shared",
    "Private",
  ]);
  const [priceRange, setPriceRange] = useState({ min: -2, max: 100000 });
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const handleLoadingState = () => {
    setIsLoading(true);
    setData([]);
  };
  const resetPageCount = () => {
    setCurrentPage(0);
    setPageCount(1);
  };

  useEffect(() => {
    setIsLoading(true);
    setData([]);

    const fetchData = async () => {
      const filtersRequest = {
        state: stateSelected,
        price: priceRange,
        propertyType: propertyTypeSelected,
      };
      const result = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/prod/getall`,
        {
          filters: filtersRequest,
        }
      );
      const offset = currentPage * PER_PAGE;
      setPageCount(Math.ceil(result?.data?.message?.length / PER_PAGE));

      const allProperties = result?.data?.message?.map((currProperty: any) => {
        return {
          address: currProperty?.address,
          state: currProperty?.state,
          productDescription: currProperty?.description,
          phoneNumber: currProperty?.phoneNumber,
          id: currProperty?.id,
          email: currProperty?.email,
          propertyType: currProperty?.propertyType,
          price: currProperty?.price,
          rooms: currProperty?.rooms,
          postedDate: currProperty?.postedDate,
          images: currProperty?.imageFiles?.map((imageFile: any) => {
            return {
              original: "https://rent0134-images.s3.amazonaws.com/" + imageFile,
              thumbnail:
                "https://rent0134-images.s3.amazonaws.com/" + imageFile,
            };
          }),
        };
      });

      const currentProperties = allProperties.slice(offset, offset + PER_PAGE);

      setData(currentProperties);
      setIsLoading(false);
    };
    fetchData();
  }, [stateSelected, priceRange, propertyTypeSelected, currentPage]);

  useEffect(() => {
    const fetchInitalData = async () => {
      const filtersRequest = {
        state: [],
        price: priceRange,
        propertyType: propertyTypeSelected,
      };
      const result = await axios.post(
        `${process.env.REACT_APP_HOST_URL}/prod/getall`,
        {
          filters: filtersRequest,
        }
      );
      const statesSet = new Set<string>();

      result.data.message?.forEach((curr: any) => {
        statesSet.add(curr.state);
      });

      let filterStates: string[] = [];
      statesSet.forEach((val: string) => {
        const stateLabel = codeToStateMapper(val);
        if (stateLabel.length > 0) {
          filterStates.push(stateLabel);
        }
      });
      setCurrStates(filterStates);
    };
    fetchInitalData();
  }, []);

  return (
    <>
      <FilterBar
        propertyTypeSelected={propertyTypeSelected}
        currStates={currStates}
        resetPageCount={resetPageCount}
        setStateSelected={setstateSelected}
        setPriceRange={setPriceRange}
        handleLoadingState={handleLoadingState}
        setPropertyTypeSelected={setPropertyTypeSelected}
        messages={messages}
      />
      <div className="container mx-auto p-4 max-w-screen-xl">
        <div className="flex flex-wrap justify-evenly -mx-4 pt-2 shadow-2xl bg-white rounded-md">
          <>
            {data?.length > 0 || !isLoading
              ? data?.map((currProduct, idx: number) => {
                  return (
                    <PropertyCardV2
                      description={currProduct.productDescription}
                      address={currProduct.address}
                      state={currProduct.state}
                      isOnPropertyForm={false}
                      email={currProduct.email}
                      phoneNumber={currProduct.phoneNumber}
                      timestamp={currProduct.postedDate}
                      rooms={currProduct?.rooms}
                      propertyType={currProduct?.propertyType}
                      id={currProduct.id}
                      price={currProduct.price}
                      gallery={currProduct.images}
                      imageFiles={[]}
                      key={`product-num-${idx}`}
                      messages={messages}
                    />
                  );
                })
              : Array.from(Array(9).keys()).map(() => <CardSkeleton />)}
            {data?.length === 0 && !isLoading && (
              <>
                <Flex
                  sx={{
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "20px",
                    flexDirection: "column",
                    img: {
                      width: "fit-content",
                    },
                  }}
                >
                  <Image
                    src={NoResults}
                    rounded={false}
                    raised={true}
                    alt="Image"
                  />
                  <Text
                    sx={{
                      fontFamily: "cursive",
                      fontSize: "40px",
                      fontWeight: "600",
                    }}
                  >
                    {messages.no_results}
                  </Text>
                  <Text
                    sx={{
                      fontFamily: "system-ui",
                      fontSize: "20px",
                      fontWeight: "400",
                    }}
                  >
                    {messages.no_results_filter}
                  </Text>
                </Flex>
              </>
            )}

            <Box
              sx={{
                width: "100%",
                margin: "30px",
              }}
            >
              <Flex
                sx={{
                  ".pagination": {
                    "-webkit-tap-highlight-color": "transparent",
                  },
                  justifyContent: "center",
                }}
              >
                <ReactPaginate
                  previousLabel={messages.prev}
                  nextLabel={messages.next}
                  breakLabel={"..."}
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"pagination__link--disabled"}
                  activeClassName={"pagination__link--active"}
                  forcePage={currentPage}
                  pageCount={pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  onPageChange={(currPage) => {
                    // setTimeout(() => {
                    //   window.scrollTo({
                    //     top: 0,
                    //     behavior: "smooth",
                    //   });
                    // }, 800);
                    setCurrentPage(currPage["selected"]);
                  }}
                />
              </Flex>
            </Box>
          </>
        </div>
      </div>
    </>
  );
};

export { PropertiesContainer };
