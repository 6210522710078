import {
  Button,
  Checkbox,
  Code,
  Group,
  NumberInput,
  Paper,
  Select,
  Stepper,
  TextInput,
  Textarea,
  Modal,
  LoadingOverlay,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications, Notifications } from "@mantine/notifications";
import { v4 as uuid } from "uuid";
import {
  IconAddressBook,
  IconHomeCheck,
  IconChecklist,
  IconPhotoPlus,
  IconCheck,
  IconCloudUpload,
  IconX,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { FormFields, PreparedForm, allStates } from "../../Types/FormTypes";
import { ImageDropV2 } from "../ImageDrop/ImageDropv2";
import { PropertyCardV2 } from "../PropertyCard/PropertyCardV2";
import { useDisclosure } from "@mantine/hooks";
import { TermsOfService } from "./TermsOfService";
import { uploadFile } from "../../utils/requests";
import { ClimbingBoxLoader } from "react-spinners";
import { Conformation } from "./Conformation";
import { Translations } from "../../utils/translations";
import { formatDate } from "../../utils/helpers";
const initialValues: FormFields = {
  address: "",
  state: "",
  email: "",
  phoneNumber: "",
  propertyType: "",
  price: undefined,
  rooms: 0,
  description: "",
  imageFiles: [],
  // verificationCheckbox: false,
  acknowledgeCheckbox: false,
};

interface PropertyFormProps {
  messages: Translations;
}

const PropertyFormv2 = (props: PropertyFormProps) => {
  // TODO ADDRESS IS REQUIRED
  const [active, setActive] = useState(0);
  const [
    isOverlayActive,
    { open: openLoadingOverlay, close: closeLoadingOverlay },
  ] = useDisclosure(false);
  const [formStatus, setFormStatus] = useState<number>(0);
  const [termsOpened, { open, close }] = useDisclosure(false);
  const {
    email,
    phone_number,
    first_step_label,
    first_step_description,
    second_step_label,
    bedrooms,
    second_step_description,
    address,
    price,
    state,
    type,
    drag_drop,
    address_description,
    price_description,
    image_err,
    third_step_label,
    third_step_description,
    fourth_step_label,
    fourth_step_description,
    property_description_label,
    property_description_desc,
    property_description_placeholder,
    no_results_state,
    state_dropdown_desc,
    rooms_dropdown_desc,
    property_type_desc,
    failed_post_notification,
    image_upload_desc,
  } = props.messages;
  const { messages } = props;

  const form = useForm({
    initialValues: initialValues,
    validate: (values) => {
      if (active === 0) {
        const { email, phoneNumber } = values;
        return {
          email:
            email === ""
              ? messages.form_email_required
              : /^\S+@\S+$/.test(email)
              ? null
              : messages.form_email_invalid,
          phoneNumber:
            phoneNumber === ""
              ? messages.form_phone_required
              : /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
                  phoneNumber
                )
              ? null
              : messages.form_phone_invalid,
        };
      }
      if (active === 1) {
        const { address, price, state, description, rooms, propertyType } =
          values;
        return {
          address: address === "" ? messages.form_address_required : null,
          state: state === "" ? messages.form_state_required : null,
          rooms: rooms === 0 ? messages.form_rooms_required : null,
          propertyType:
            propertyType === "" ? messages.form_property_type_required : null,
          description:
            description === ""
              ? messages.form_description_required
              : description.split(" ").filter(Boolean).length < 250
              ? null
              : messages.form_description_max_words,
        };
      }
      if (active === 3) {
        const { acknowledgeCheckbox } = values;
        return {
          acknowledgeCheckbox:
            acknowledgeCheckbox === false
              ? messages.form_checkbox_required
              : null,
        };
      }
      return {};
    },
  });
  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 4 ? current + 1 : current;
    });
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const uploadRegistrationForm = (form: string) => {
    openLoadingOverlay();
    notifications.show({
      id: "form-update",
      loading: true,
      icon: <IconCloudUpload size="1rem" />,
      title: "Uploading your property",
      message: "Please wait while we verify and publish your listing",
      autoClose: false,
      withCloseButton: false,
    });

    fetch(`${process.env.REACT_APP_HOST_URL}/prod/submit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ form }),
    })
      .then((response) => {
        setFormStatus(response.status);
      })
      .catch((error) => {
        setFormStatus(500);
        console.log(error);
      });
  };

  useEffect(() => {
    closeLoadingOverlay();
    if (formStatus === 200) {
      nextStep();
      notifications.update({
        id: "form-update",
        loading: false,
        color: "teal",
        title: "Upload Successful",
        message: "Thank you for your submission.",
        icon: <IconCheck size="1rem" />,
        autoClose: 20000,
        withCloseButton: true,
      });
    } else if (formStatus !== 0) {
      notifications.update({
        id: "form-update",
        loading: false,
        color: "red",
        title: "Upload Failed",
        message: failed_post_notification,
        icon: <IconX size="1rem" />,
        autoClose: 20000,
        withCloseButton: true,
      });
    }
  }, [closeLoadingOverlay, formStatus, nextStep]);

  const uploadForm = () => {
    if (!form.validate().hasErrors) {
      const {
        imageFiles,
        phoneNumber,
        address,
        email,
        state,
        propertyType,
        price,
        rooms,
        description,
      } = form.values;
      const final_price = price === undefined ? -1 : price;
      const imgFiles: string[] = [];

      imageFiles.forEach((image, idx) => {
        const currentImage = "" + phoneNumber + "_" + uuid() + "_" + Date.now();
        imgFiles.push(currentImage);
        uploadFile(currentImage, image);
      });

      const preparedForm: PreparedForm = {
        address,
        email,
        propertyType,
        phoneNumber,
        state,
        price: final_price,
        rooms: parseInt(rooms as unknown as string),
        description,
        imageFiles: imgFiles,
      };
      uploadRegistrationForm(JSON.stringify(preparedForm));
    }
  };

  return (
    <>
      <LoadingOverlay
        loader={<ClimbingBoxLoader color="#36D7B7" size={50} />}
        sx={{
          position: "fixed",
        }}
        visible={isOverlayActive}
        overlayBlur={2}
      />
      <div style={{ padding: 16, margin: "auto", maxWidth: 1600 }}>
        <form>
          <Paper shadow="lg" radius="md" sx={{ padding: "20px" }}>
            <Notifications />
            <Modal opened={termsOpened} onClose={close} centered>
              <TermsOfService />
            </Modal>
            <Stepper
              active={active}
              breakpoint={1300}
              color="green"
              orientation="horizontal"
            >
              <Stepper.Step
                icon={<IconAddressBook size="1.4rem" />}
                label={first_step_label}
                description={first_step_description}
              >
                <TextInput
                  withAsterisk
                  label={email}
                  placeholder={email}
                  {...form.getInputProps("email")}
                />
                <TextInput
                  mt="md"
                  withAsterisk
                  label={phone_number}
                  placeholder="xxx-xxx-xxxx"
                  {...form.getInputProps("phoneNumber")}
                />
              </Stepper.Step>

              <Stepper.Step
                icon={<IconHomeCheck size="1.4rem" />}
                label={second_step_label}
                description={second_step_description}
              >
                <TextInput
                  withAsterisk
                  description={address_description}
                  label={address}
                  placeholder={address}
                  {...form.getInputProps("address")}
                />
                <Select
                  mt="md"
                  searchable
                  withAsterisk
                  data={allStates}
                  label={state}
                  maxDropdownHeight={280}
                  nothingFound={no_results_state}
                  description={state_dropdown_desc}
                  {...form.getInputProps("state")}
                />
                <Select
                  mt="md"
                  withAsterisk
                  data={["1", "2", "3", "4", "5"]}
                  label={bedrooms}
                  description={rooms_dropdown_desc}
                  {...form.getInputProps("rooms")}
                />
                <Select
                  mt="md"
                  withAsterisk
                  data={["Shared", "Private"]}
                  label={type}
                  description={property_type_desc}
                  {...form.getInputProps("propertyType")}
                />
                <NumberInput
                  mt="md"
                  label={price}
                  description={price_description}
                  prefix="$"
                  stepHoldDelay={500}
                  stepHoldInterval={(t) => Math.max(1000 / t ** 2, 25)}
                  {...form.getInputProps("price")}
                />
                <Textarea
                  label={property_description_label}
                  description={property_description_desc}
                  placeholder={property_description_placeholder}
                  autosize
                  withAsterisk
                  mt="md"
                  minRows={2}
                  maxRows={5}
                  {...form.getInputProps("description")}
                />
              </Stepper.Step>

              <Stepper.Step
                icon={<IconPhotoPlus size="1.4rem" />}
                label={third_step_label}
                description={third_step_description}
              >
                <ImageDropV2
                  form={form}
                  tooManyErrorMsg={image_err}
                  drag_drop={drag_drop}
                  image_upload_desc={image_upload_desc}
                />
              </Stepper.Step>

              <Stepper.Step
                icon={<IconChecklist size="1.4rem" />}
                label={fourth_step_label}
                description={fourth_step_description}
              >
                <Group position="center">
                  <PropertyCardV2
                    {...form.values}
                    gallery={[]}
                    id=""
                    timestamp={formatDate(new Date().toString())}
                    isOnPropertyForm={true}
                    messages={messages}
                  />
                </Group>
                <Group>
                  <Checkbox
                    pt={50}
                    label="By clicking the 'Submit' button below, I hereby acknowledge that I have read and understood the following Terms of Service (referred to as the 'Terms') and I agree to comply with their provisions. Furthermore, I affirm that I will provide accurate and non-discriminatory information in accordance with these Terms."
                    {...form.getInputProps("acknowledgeCheckbox", {
                      type: "checkbox",
                    })}
                  />
                  <Button onClick={open}>View Terms</Button>
                </Group>
              </Stepper.Step>

              <Stepper.Completed>
                <Conformation messages={messages} />
              </Stepper.Completed>
            </Stepper>

            <Group position="right" mt="xl">
              {active !== 0 && active !== 4 && (
                <Button variant="default" onClick={prevStep}>
                  Back
                </Button>
              )}
              {active < 3 && (
                <Button onClick={nextStep}>{messages.next_page}</Button>
              )}
              {active === 3 && (
                <Button onClick={uploadForm}>{messages.submit}</Button>
              )}
              {active === 4 && (
                <Button
                  onClick={() => {
                    window.location.replace("/");
                  }}
                >
                  {messages.form_go_to_home}
                </Button>
              )}
            </Group>
          </Paper>
        </form>
      </div>
    </>
  );
};

export { PropertyFormv2 };
