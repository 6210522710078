import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Flex } from "theme-ui";

const CardSkeleton = () => {
  return (
    <div className="xsmall:w-full sm:w-1/2 md:w-1/2 xl:w-1/3 p-4">
      <div
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
        }}
        className="c-card block bg-white shadow-xl mb-4 hover:shadow-xl rounded-lg overflow-hidden"
      >
        <Skeleton variant="rectangular" animation="wave" height={300} />
        <Flex sx={{ justifyContent: "center", alignItems: "center" }}>
          <Skeleton
            sx={{
              marginTop: "10px",
              width: "25%",
              marginLeft: "5px",
              marginRight: "5px",
            }}
            variant="rectangular"
            height={90}
          />
          <Skeleton
            sx={{
              marginTop: "10px",
              width: "25%",
              marginLeft: "5px",
              marginRight: "5px",
            }}
            variant="rectangular"
            height={90}
          />
          <Skeleton
            sx={{
              marginTop: "10px",
              width: "25%",
              marginLeft: "5px",
              marginRight: "5px",
            }}
            variant="rectangular"
            height={90}
          />
        </Flex>
        <Skeleton sx={{ marginTop: "20px" }} variant="text" />
        <Skeleton variant="text" animation="wave" />
        <Skeleton variant="text" animation="wave" />
        <Skeleton variant="text" animation="wave" />
        <div className="p-4 border-t border-b text-xs text-gray-700">
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
        </div>
      </div>
    </div>
  );
};

export { CardSkeleton };
