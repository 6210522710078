import React from "react";
import { Flex, Text } from "theme-ui";
import { PillDropdown } from "../PillDropdown";
import { PriceRange } from "../PriceRange";
import { Checkbox, FormGroup, FormControlLabel } from "@mui/material";

interface FilterBarProps {
  messages: {
    shared: string;
    private: string;
    starting_price: string;
    maximum_price: string;
    type: string;
    filters: string;
    state: string;
    email: string;
    address: string;
    phone_number: string;
    price: string;
    bedroom: string;
    bedrooms: string;
    rental_description: string;
    drag_drop: string;
    success_modal_text: string;
    success_modal_header: string;
    fail_modal_head: string;
    fail_modal_sub_header: string;
    fail_modal_text: string;
    try_again: string;
    less_than_max_err: string;
    more_than_min_err: string;
    no_results: string;
    no_results_filter: string;
  };
  propertyTypeSelected: string[];
  currStates: string[];
  resetPageCount: () => void;
  setStateSelected?: any;
  setPriceRange?: any;
  handleLoadingState: any;
  setPropertyTypeSelected: any;
}

const FilterBar = (props: FilterBarProps) => {
  const {
    propertyTypeSelected,
    setPropertyTypeSelected,
    currStates,
    handleLoadingState,
    setStateSelected,
    resetPageCount,
    setPriceRange,
    messages,
  } = props;
  return (
    <Flex
      pb="3"
      sx={{
        width: "100%",
        alignItems: "center",
        flexWrap: "wrap",
        boxShadow:
          "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
      }}
    >
      <Text
        sx={{
          fontSize: "20px",
          fontWeight: "600",
          marginRight: "30px",
          marginLeft: "20px",
          marginTop: "12px",
        }}
      >
        {messages.filters}:
      </Text>

      <PillDropdown
        label={messages.state}
        options={currStates}
        setSelectedState={setStateSelected}
        handleLoadingState={handleLoadingState}
        resetPageCount={resetPageCount}
      />
      <FormGroup
        sx={{ paddingLeft: "20px", paddingTop: "10px", flexDirection: "row" }}
      >
        {/* <FormControlLabel label={""}/> */}
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              color="success"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                resetPageCount();
                if (event.target.checked) {
                  setPropertyTypeSelected([...propertyTypeSelected, "Shared"]);
                } else {
                  setPropertyTypeSelected(
                    propertyTypeSelected.filter((v) => v !== "Shared")
                  );
                }
              }}
            />
          }
          label={messages.shared}
        />
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked
              color="success"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                resetPageCount();
                if (event.target.checked) {
                  setPropertyTypeSelected([...propertyTypeSelected, "Private"]);
                } else {
                  setPropertyTypeSelected(
                    propertyTypeSelected.filter((v) => v !== "Private")
                  );
                }
              }}
            />
          }
          label={messages.private}
        />
      </FormGroup>
      <PriceRange
        minLabel={messages.starting_price}
        maxLabel={messages.maximum_price}
        less_than_max_err={messages.less_than_max_err}
        more_than_min_err={messages.more_than_min_err}
        setPriceRange={setPriceRange}
        resetPageCount={resetPageCount}
        handleLoadingState={handleLoadingState}
      />
    </Flex>
  );
};

export { FilterBar };
