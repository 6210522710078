import {
  createStyles,
  Image,
  Accordion,
  Grid,
  Col,
  Container,
  Title,
  ThemeIcon,
  List,
  Text,
} from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons-react";
import FAQ from "../../assets/FAQ.svg";
import { Translations } from "../../utils/translations";

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: `calc(${theme.spacing.xl} * 2)`,
    paddingBottom: `calc(${theme.spacing.xl} * 2)`,
  },

  title: {
    marginBottom: theme.spacing.md,
    paddingLeft: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  item: {
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[1]
        : theme.colors.gray[7],
  },
}));

function FaqWithImage(props: { messages: Translations }) {
  const { messages } = props;
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <Container size="lg">
        <Grid id="faq-grid" gutter={50}>
          <Col span={12} md={6}>
            <Image src={FAQ} alt="Frequently Asked Questions" />
          </Col>
          <Col span={12} md={6}>
            <Title order={2} ta="left" className={classes.title}>
              {messages.faq_title}
            </Title>

            <Accordion
              chevronPosition="right"
              defaultValue="reset-password"
              variant="separated"
            >
              <Accordion.Item className={classes.item} value="reset-password">
                <Accordion.Control>
                  {messages.faq_adding_listing_title}
                </Accordion.Control>
                <Accordion.Panel>
                  <List
                    spacing="xs"
                    size="sm"
                    center
                    icon={
                      <ThemeIcon color="teal" size={24} radius="xl">
                        <IconCircleCheck size="1rem" />
                      </ThemeIcon>
                    }
                  >
                    <List.Item>
                      <Text>{messages.faq_adding_listing_step_1}</Text>
                    </List.Item>
                    <List.Item>
                      <Text>{messages.faq_adding_listing_step_2}</Text>
                    </List.Item>
                    <List.Item>
                      <Text>{messages.faq_adding_listing_step_3}</Text>
                    </List.Item>
                    <List.Item>
                      <Text>{messages.faq_adding_listing_step_4}</Text>
                    </List.Item>
                    <List.Item>
                      <Text>{messages.faq_adding_listing_step_5}</Text>
                    </List.Item>
                    <List.Item>
                      <Text>{messages.faq_adding_listing_step_6}</Text>
                    </List.Item>
                  </List>
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="another-account">
                <Accordion.Control>
                  {messages.faq_delete_title}
                </Accordion.Control>
                <Accordion.Panel>{messages.faq_delete_body}</Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="newsletter">
                <Accordion.Control>
                  {messages.faq_image_limit_title}
                </Accordion.Control>
                <Accordion.Panel>
                  {messages.faq_image_limit_body}
                </Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="credit-card">
                <Accordion.Control>
                  {messages.faq_privacy_title}
                </Accordion.Control>
                <Accordion.Panel>{messages.faq_privacy_body}</Accordion.Panel>
              </Accordion.Item>

              <Accordion.Item className={classes.item} value="payment">
                <Accordion.Control>{messages.faq_cost_title}</Accordion.Control>
                <Accordion.Panel>{messages.faq_cost_body}</Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Grid>
      </Container>
    </div>
  );
}

export { FaqWithImage };
