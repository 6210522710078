import React from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import { stateCodeMapper } from "../../utils/mapping";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface PillDropdownProps {
  label: string;
  setSelectedState: any;
  options: string[];
  handleLoadingState: any;
  resetPageCount: () => void;
}

const PillDropdown = (props: PillDropdownProps) => {
  const {
    label,
    options,
    setSelectedState,
    handleLoadingState,
    resetPageCount,
  } = props;
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    resetPageCount();
    const mappedStates = value.map((currState: string) => {
      return stateCodeMapper(currState);
    });

    handleLoadingState();
    setSelectedState(mappedStates);

    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    // <div>
    <FormControl
      sx={{
        marginLeft: "18px!important",
        marginTop: "12px!important",
        width: 300,
      }}
    >
      <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
      <Select
        labelId="multiple-chip-label"
        id="multiple-chip"
        multiple
        value={personName}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    // </div>
  );
};

export { PillDropdown };
