import { CSSProperties } from "react";

const thumb: CSSProperties = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",

  width: 100,
  height: 100,
  boxSizing: "border-box",
};

const thumbInner: CSSProperties = {
  display: "flex",
  minWidth: 0,
  // overflow: "hidden",
};

const img: CSSProperties = {
  display: "block",
  width: "auto",
  height: "100%",
};

export const ImagePreviewThumbs = (props: {
  image: any;
  handleThumbDelete: (file: File) => void;
}) => {
  const { image, handleThumbDelete } = props;
  return (
    <div
      style={{
        height: "100px",
      }}
      className="animate-wiggle mt-4 mr-4 max-w-sm rounded overflow-hidden shadow-lg"
    >
      <button
        type="button"
        style={{ left: "75%" }}
        onClick={() => handleThumbDelete(image)}
        className="bg-white animate-bounce p-0 rounded-xl absolute top-2 inline-flex items-center justify-center text-red-600 hover:text-red-400 hover:bg-black focus:outline-none"
      >
        <svg
          className="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div style={thumb} key={image.name}>
        <div style={thumbInner}>
          <img
            alt="preview thumb"
            src={URL.createObjectURL(image)}
            style={img}
          />
        </div>
      </div>
    </div>
  );
};
