import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import logo from "../../assets/Selledalogo.png";
import { Flex } from "theme-ui";

interface HeaderProps {
  lang: string;
  routeLabel: string;
  comingSoon: string;
  viewAllLabel: string;
  homePageLinkLabel: string;
  routeLink: string;
  setLanguage: any;
}

const toggleButtonStyles = {
  width: "150px",
  border: "2px solid darkgray",
};

const Headerv2 = (props: HeaderProps) => {
  const {
    lang,
    setLanguage,
    routeLabel,
    viewAllLabel,
    homePageLinkLabel,
    routeLink,
  } = props;
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment) {
      setLanguage(newAlignment);
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-gray-800">
      <div className="">
        <div className="flex items-center justify-between h-24">
          <div className="flex justify-between items-center w-full">
            <Flex
              sx={{ "-webkit-tap-highlight-color": "transparent" }}
              className="flex-shrink-0"
            >
              <a href={"/"}>
                <img className="w-72 -mt-1 -ml-4" src={logo} alt="Workflow" />
              </a>
            </Flex>

            <div className="md:hidden sm:hidden xsmall:hidden lg:block w-full">
              <div className="ml-10 flex items-baseline justify-between space-x-4">
                <div>
                  <a
                    href="/"
                    className=" bg-gray-600 hover:bg-gray-200 mr-2 hover:text-black text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {homePageLinkLabel}
                  </a>
                  <a
                    href="/search"
                    className=" bg-gray-600 hover:bg-gray-200 mr-2 hover:text-black text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {viewAllLabel}
                  </a>
                  <a
                    href={routeLink}
                    className=" bg-gray-600 hover:bg-gray-200 mr-2 hover:text-black text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    {routeLabel}
                  </a>
                </div>

                <ToggleButtonGroup
                  value={lang}
                  exclusive
                  onChange={handleChange}
                  sx={{
                    paddingRight: "65px",
                    ".MuiButtonBase-root.Mui-selected": {
                      color: "rgba(0, 255, 0, 1)",
                      backgroundColor: "rgba(46, 125, 50, 0.2)",
                      width: "150px",
                      transition:
                        "background-color 0.5s, color 0.5s, transform 2s",
                      border: "1px solid",
                      borderRight: "2px solid",
                    },
                    ".MuiButtonBase-root.Mui-selected:focus": {
                      outline: "none",
                    },
                    ".MuiButtonBase-root.Mui-selected:hover": {
                      backgroundColor: "rgba(46, 125, 50, 0.6)",
                    },
                    ".MuiButtonBase-root": {
                      fontWeight: 500,
                      width: "150px",
                      padding: "7px",
                      backgroundColor: "rgba(0, 0, 0, 0.4)",
                      color: "white",
                      transition:
                        "background-color 0.5s, color 0.5s, transform 2s",
                    },
                    ".MuiButtonBase-root:focus": {
                      outline: "none",
                    },
                    ".MuiButtonBase-root:hover": {
                      backgroundColor: "rgba(46, 125, 50, 0.6)",
                    },
                  }}
                >
                  <ToggleButton
                    value="en"
                    sx={toggleButtonStyles}
                    color="success"
                  >
                    English
                  </ToggleButton>
                  <ToggleButton
                    value="amh"
                    sx={toggleButtonStyles}
                    color="success"
                  >
                    አማርኛ
                  </ToggleButton>
                  {/* <ToggleButton
                    value="tig"
                    sx={toggleButtonStyles}
                    color="success"
                  >
                    ትግርኛ
                  </ToggleButton> */}
                </ToggleButtonGroup>
              </div>
            </div>
          </div>
          <div className="pr-5 lg:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none ring-2 ring-offset-2 ring-offset-gray-800 ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-800 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-800 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="lg:hidden" id="mobile-menu">
            <div
              ref={ref}
              className="px-2 pt-2 flex flex-col pb-3 space-y-1 sm:px-3"
            >
              <a
                href="/"
                className=" bg-gray-600 hover:bg-gray-200 mr-2 hover:text-black text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                {homePageLinkLabel}
              </a>
              <a
                href="/search"
                className=" bg-gray-600 hover:bg-gray-200 mr-2 hover:text-black text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                {viewAllLabel}
              </a>
              <a
                href={routeLink}
                className=" bg-gray-600 hover:bg-gray-200 mr-2 hover:text-black text-white px-3 py-2 rounded-md text-sm font-medium"
              >
                {routeLabel}
              </a>
              <div className="flex justify-center items-center pt-4 pb-4">
                <ToggleButtonGroup
                  value={lang}
                  exclusive
                  onChange={handleChange}
                  sx={{
                    width: "1000%",
                    ".MuiButtonBase-root.Mui-selected": {
                      color: "rgba(0, 255, 0, 1)",
                      width: "50%",
                      backgroundColor: "rgba(46, 125, 50, 0.2)",
                      transition:
                        "background-color 0.5s, color 0.5s, transform 2s",
                      border: "1px solid",
                      borderRight: "2px solid",
                    },
                    ".MuiButtonBase-root.Mui-selected:focus": {
                      outline: "none",
                    },
                    ".MuiButtonBase-root.Mui-selected:hover": {
                      backgroundColor: "rgba(46, 125, 50, 0.6)",
                    },
                    ".MuiButtonBase-root": {
                      fontWeight: 500,
                      width: "50%",
                      padding: "7px",
                      backgroundColor: "rgba(0, 0, 0, 0.4)",
                      color: "white",
                      transition:
                        "background-color 0.5s, color 0.5s, transform 2s",
                    },
                    ".MuiButtonBase-root:focus": {
                      outline: "none",
                    },
                    ".MuiButtonBase-root:hover": {
                      backgroundColor: "rgba(46, 125, 50, 0.6)",
                    },
                  }}
                >
                  <ToggleButton
                    sx={toggleButtonStyles}
                    value="en"
                    color="success"
                  >
                    English
                  </ToggleButton>
                  <ToggleButton
                    sx={toggleButtonStyles}
                    value="amh"
                    color="success"
                  >
                    አማርኛ
                  </ToggleButton>
                  {/* <ToggleButton
                    sx={toggleButtonStyles}
                    value="tig"
                    color="success"
                  >
                    ትግርኛ
                  </ToggleButton> */}
                </ToggleButtonGroup>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </nav>
  );
};

export { Headerv2 };
