import React, { useEffect, useState } from "react";
import { MantineProvider } from "@mantine/core";
import { TypographyStylesProvider } from "@mantine/core";
import { HeroContentLeft } from "./About";
import { FeaturesCards } from "./FeaturesSection";
import { FaqWithImage } from "./FAQ";
import { ContactUs } from "./ContactUs";
import { useScrollIntoView } from "@mantine/hooks";
import { Translations } from "../../utils/translations";

const LandingPage = (props: { messages: Translations }) => {
  const { messages } = props;
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });
  return (
    <MantineProvider>
      <TypographyStylesProvider>
        <div style={{ backgroundColor: "white" }}>
          <HeroContentLeft
            messages={messages}
            scrollIntoView={scrollIntoView}
          />
          <FeaturesCards messages={messages} learnMoreRef={targetRef} />
          <FaqWithImage messages={messages} />
          <ContactUs messages={messages} />
        </div>
      </TypographyStylesProvider>
    </MantineProvider>
  );
};

export { LandingPage };
